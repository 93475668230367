import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import  Chart  from "chart.js/auto";

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
    public chart: any;
    @Input() data: any;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.chart) {
            this.createChart();
        }

        this.updateData(changes.data.currentValue);
    }

    createChart() {
        this.chart = new Chart("barChart", {
            type: 'bar',
            data: this.data,
            options: {
                aspectRatio: 2.5,
                indexAxis: 'y',
                responsive: false
            }
        });
    }

    updateData(data) {
        this.chart.data.datasets.pop();
        this.chart.data.datasets.push(data.datasets[0]);
        this.chart.update();
    }
}
