<sidepanel *ngIf="popular && popular.length > 0">
    <h4 class="headerunderline">New and updated</h4>

    <div *ngFor="let object of popular" style="margin-top: 5px;">
        <a [routerLink]="'/' + object.Url" class="sideBox">
            <div class="textwrap" style="display: inline">
                <div class="type-name">{{object.TypeName}}</div>
                <div class="text-title">
                    {{object.Title}}<span class="newOrUpdate hidden-sm hidden-xs">&nbsp; {{object.NewOrUpdate}}</span>
                </div>
                <span class="newOrUpdate hidden-md hidden-lg">{{object.NewOrUpdate}}</span>
            </div>
        </a>
    </div>
</sidepanel>
