<div *ngIf="attachments && attachments.length > 0">
    <h4 class="headerunderline">{{title}}</h4>
    <div *ngFor="let object of attachments" style="margin-top: 5px;">
        <div class="mini-container bg-midblue mat-elevation-z2">
            <div style="padding: 5px;">
                <i class="fnt-white attachment-logo {{object.ArticleTypeClass}}"></i>
                <span style="font-size:0.9em">{{object.Name}}</span>
            </div>
            <div style="height: 30px;">
                <a *ngIf="showsignup" target="_self" class="attachment-button btn btn-yellow pull-right" href="{{object.Location}}"><span>Sign up</span></a>
                <a *ngIf="!showsignup && object.IsLink" target="_blank" class="attachment-button btn btn-green pull-right" href="{{object.Location}}"><span class="fa fa-link"></span> <span>View</span></a>
                <a *ngIf="!showsignup && !object.IsLink" class="attachment-button btn btn-green pull-right" (click)="recordDownload(object.ArticleAttachmentId)" href="{{object.Location}}"><span class="fa fa-download"></span> <span>Download</span></a>
            </div>
        </div>
    </div>
</div>
