import { Injectable, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Injectable()
export class BreadcrumbService {


    private stack: string[] = JSON.parse(localStorage.getItem('history')) || [];

    get Length() {
        return this.stack.length;
    }

    constructor(private router: Router) { }

    public AddRoute(url: string, params: any = null) {


        //clear the stack
        if (params && params.clear) {
            this.ResetStack();
            url = url.replace("?clear=1", "");
            url = url.replace("clear=1", "");
        }

        //going back - don't log
        if (params && params.back) {
            return;
        }

        //for now only log v2admin
        if (url.startsWith('/v2admin') && url != this.stack[this.stack.length - 1] ) {
            this.stack.push(url);
            this.UpdateStack();
        }
        else if (!url.startsWith('/v2admin')) {
            this.ResetStack();
        }

        
    }

    public GoBack(amount: number = 1) {

        if (this.stack.length <= 1) {
            window.history.back();
            return;
        }

        if (amount == 1) {
            this.stack.pop();
        }
        else {
            this.stack.splice(this.stack.length - amount);
        }

        this.UpdateStack();

        var url = this.stack[this.stack.length - 1];

        if (url.indexOf('?') > 0) {
            this.router.navigateByUrl(this.stack[this.stack.length - 1] + '&back=1');
        }
        else {
            this.router.navigateByUrl(this.stack[this.stack.length - 1] + '?back=1');
        }
    }

    public Disabled() {
        return this.stack.length <= 1;
    }

    private ResetStack() {
        this.stack = [];
        localStorage.removeItem('history');
    }

    private UpdateStack() {
        localStorage.removeItem('history');
        localStorage.setItem('history', JSON.stringify(this.stack));
    }


}
