import { Component, Input } from '@angular/core';

@Component({
    selector: 'feature-sm',
    templateUrl: './feature-sm.component.html',
    styleUrls: ['./feature-sm.component.scss'],
})
export class FeatureSmComponent {

    @Input() feature: any;
}
