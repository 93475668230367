<style>
    .fa-icon{
        
    }
</style>
<div>
    <table class="table">
        <tbody>
            <tr class="subcategory-box margin-top-bottom-20 mat-elevation-z2" *ngFor="let subcategory of data">
                <td class="col-md-4 subcategory-box">
                    <div class="subcategory-h2">
                        {{subcategory.Title}}
                    </div>
                </td>
                <td class="col-md-8 topics-in-subcategory-box">
                    <div class="individual-topics" *ngFor="let topic of subcategory[childName]">
                        <div class="table-topic-divider">
                            <a [routerLink]="'/' + topic.Url">
                                <span [class]="topic.Class"></span>
                                {{topic.Title}}
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>

    </table>
</div>

