<div class="content-container">
    <div>
        Hub4Leaders uses cookies to ensure that we give you the best user experience of our website.
        If you give your consent, we will use your cookies to optimise your experience.
        If you do not provide your consent, certain features of Our Site may not function.
        For full details, read our <span class="policy-link" (click)="viewCookiePolicy()">cookie policy</span> and <span class="policy-link" (click)="viewPrivacyPolicy()">privacy policy</span>.
    </div>
    <div class="button-container">
        <div class="btn button" (click)="manageCookies()">Manage Cookies</div>
        <div class="btn button btn-primary" (click)="acceptCookies()">Accept and close</div>
    </div>
</div>
