import { Component, Input } from '@angular/core';

@Component({
    selector: 'feature-md',
    templateUrl: './feature-md.component.html',
    styleUrls: ['./feature-md.component.scss'],
})
export class FeatureMdComponent {

    @Input() feature: any;
    @Input() showSubHeader: boolean;
    @Input() showImg: boolean = true; 
}
