
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'
import { ArticleService } from '../../../article/article.service'


@Component({
    selector: 'attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.css'],
    providers: [ArticleService],


})
export class AttachmentsComponent implements OnInit {

    @Input() attachments: any;
    @Input() showsignup: boolean;
    title: string;


    constructor(private articleService: ArticleService) { }

    ngOnInit(): void {
        this.title = this.attachments.length > 1 ? "Downloads" : "Download";
    }

    recordDownload(id: number): void {
        this.articleService.SaveRecordDownload(id).subscribe();
    }
}
