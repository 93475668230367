
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'
import { ArticleService } from '../../../article/article.service'


@Component({
    selector: 'mostpopular',
    templateUrl: './mostpopular.component.html',
    styleUrls: ['./mostpopular.component.scss'],
    providers: [ArticleService]
})
export class MostPopularComponent implements OnInit{

    @Input() articles: any;
    @Input() articleTypeId: number;
    @Input() categoryId: number;
    popular: any;

    constructor(
        private articleService: ArticleService,
        private route: ActivatedRoute)
    { }

    ngOnInit(): void {

        if (this.articleTypeId) {

            this.articleService.getMostPopularArticlesByType(this.articleTypeId)
                .subscribe(popular => this.popular = popular);
        }
        else if (this.categoryId) {

            this.articleService.getMostPopularArticles(this.categoryId)
                .subscribe(popular => {
                    this.popular = popular;
                });
        }
        else if (this.articles) {
                this.popular = this.articles;
        }
        else {

            this.route.params.pipe(
                switchMap((params: Params) => this.articleService.getMostPopularArticles(params['id'])))                
                .subscribe(popular => this.popular = popular);
        }
    }
}
