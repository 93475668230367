<div>
    <section class="container login">
        <div class="row">
            <div class="col-md-5" style="padding-top:30px;">

                <sidepanel [h1title]="'Account Inactive'">
                    <div class="wrapper">
                        <p style="color:#686464 !important">{{email}}
                            Unfortunately, your account has not been activated.
                            An activation email has been sent, but it may have found its way to your junk folder.
                            If you cannot find the email, click the button below to resend your activation link.
                            If you experience any difficulties,
                            please call <strong>01625 464 420</strong> to speak to an advisor or email <a href="mailto:driver@theschoolbus.net">driver@theschoolbus.net</a>.
                        </p>
                        <div class="btnwrapper">
                            <button type="button" class="btn btn btn-yellow" *ngIf="!emailSent" (click)="sendEmail()">send email</button>
                        </div>
                        <div *ngIf="emailSent" class="alert alert-success" role="alert">Email Sent</div>
                    </div>
                </sidepanel>
            </div>
            <div class="col-md-7 hidden-xs">
                <div class="right">
                    <img src="/Img/core/login/locked.png" style="margin-top: 70px" />
                </div>
            </div>
        </div>
    </section>

</div>
