import { Component, Input } from '@angular/core';

@Component({
    selector: 'parent-child-list-md',
    templateUrl: './parent-child-list-md.component.html',
    styleUrls: ['./parent-child-list-md.component.scss'],
})
export class ParentChildListMdComponent {

    @Input() data: any;
    @Input() childName: string;
}
