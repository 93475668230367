import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'categoryfilter',
    pure: false,
})

export class CategoryFilterPipe implements PipeTransform {
    transform(items: any[], filters: any[]): any[] {

        if (!items || !filters) {
            return items;
        }

        return items.filter(item => filters.findIndex(i => i.name === item.Category && i.value == true) != -1)
    }
}
