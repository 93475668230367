<div class="col-md-12">

    <div class="row is-table-row subcategory-box margin-top-bottom-20 clearfix mat-elevation-z2" *ngFor="let subcategory of data">
        <div class="col-md-4">
            <div class="subcategory-h2">
                {{subcategory.Title}}
            </div>
        </div>
        <div class="col-md-8 topics-in-subcategory-box">
            <div class="individual-topics" *ngFor="let topic of subcategory[childName]">
                <div class="margin-top-bottom-10">
                    <a [routerLink]="'/' + topic.Url">
                        <span [class]="topic.Class"></span>
                        {{topic.Title}}
                    </a>
                </div>
            </div>
            <div class="clearfix" ng-repeat-end></div>
        </div>
    </div>

</div>
