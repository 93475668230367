import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';


//declare var userId: number;
//declare var _userRoleClaims: string;

@Injectable()
export class AuthService {

    private checkDone: boolean = false;
    private isAuthenticated: boolean;
    private timer: any;
    private userProfile: any;
    pageTitle: string;

    loggedIn: boolean = false;
    loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

    isSaving: boolean = false;
    isSaving$ = new BehaviorSubject<boolean>(this.isSaving);

    firstTermsAccepted$ = new Subject<boolean>();
    isLive: boolean = false;

    showFooter: boolean = true;
    _showFooter = new BehaviorSubject<boolean>(this.showFooter);
    showFooter$ = this._showFooter.asObservable();

    public cookiePreferencesSetSubject = new Subject<boolean>();
    public cookiePreferncesSet = this.cookiePreferencesSetSubject.asObservable();

    menuDirty$ = new Subject();

    private authPeriod: number = 3600000;

    showMigrationBannerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router,
        private http: HttpClient) {
        
        this.setKeepLoggedIn(0);

        // If authenticated, set local profile property and update login status subject
        // If token is expired, log out to clear any data from localStorage
        if (this.authenticated) {
            this.userProfile = JSON.parse(localStorage.getItem('profile'));
            this.setLoggedIn(true);

        } else {
            this.logout();
        }
    }

    ngOnDestroy() {

        window.clearTimeout(this.timer);
    }

    private setKeepLoggedIn(timeout: number) {

        this.timer = window.setTimeout(() => {
            this.GetCurrentUser()
                .subscribe(result => {

                    if (result.userProfile.Authorised) {
                        this.login(result.userProfile);
                        //this.setKeepLoggedIn(this.authPeriod / 4);
                    } else {
                        this.logout();
                    }
                    
                })
        }, timeout);
    }

    private setLoggedIn(value: boolean) {
        // Update login status subject
        this.loggedIn$.next(value);
        this.loggedIn = value;
    }

    public hideFooter() {
        this.showFooter = false;
        this._showFooter.next(this.showFooter);
    }

    public refreshMenu() {
        this.menuDirty$.next(true);
    }

    public login(userProfile: any) {
        // Auth0 authorize request
        //this.auth0.authorize();

        this.userProfile = userProfile;

        this.setKeepLoggedIn(this.authPeriod / 4);

        /*if (this.userProfile.userRoleClaims) {
            _userRoleClaims = this.userProfile.userRoleClaims;
        }*/

        if (this.userProfile.Authorised) {

            localStorage.setItem('expires_at', (Date.now() + this.authPeriod).toString());
            localStorage.setItem('profile', JSON.stringify(this.userProfile));

            this.setLoggedIn(true);
            this.initializeMigrationBanner();

            //sanity check for login check
            

        } else {
            this.setLoggedIn(false);
        }

    }

    logout() {
        sessionStorage.clear();

        localStorage.removeItem('profile');
        localStorage.removeItem('expires_at');
        this.userProfile = undefined;
        if (this.loggedIn) {
            this.setLoggedIn(false);
        }
    }

    logoutTCSAndCC() {
        let domain = window.location.host.replace('www.', '');
        let iframeLogout = (url) => {
            let iframeContainer = document.getElementById('iframe-logouts');
            let iframe = document.createElement('iframe');
            iframe.src = url;
            iframeContainer.appendChild(iframe);
        };

        iframeLogout(`https://compliancecoach.${domain}/logoutnotify`);
        iframeLogout(`https://thecompliancestandard.${domain}/api/logoutnotify/logout`);
    }

    updateProfileValue(key: string, value: any): void {
        this.userProfile[key] = value;
        localStorage.setItem('profile', JSON.stringify(this.userProfile));
    }

    get authenticated(): boolean {
        // Check if current date is greater than expiration
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return Date.now() < expiresAt;


        //if (_userRoleClaims != "") return true;
        //return false;
    }

    firstTermsAccepted(value: boolean): void {
        this.firstTermsAccepted$.next(value);
    }
    get isFirstLogOn(): boolean {   
        return this.userProfile.IsFirstLogin;
    }

    get acceptedPlusTandC(): boolean {
       
        if (!this.userProfile || !this.userProfile.Authorised || this.userProfile.LocalAuthorityId == 0) return true;

        if (this.userProfile.PlusAccepted) return true;

        return false;   
    }

    get acceptedTsbTandC(): boolean {

        if (!this.userProfile || !this.userProfile.Authorised || this.userProfile.UserTAndCAcceptance) return true;

        return false;
    }
    set acceptedTsbTandC(value: boolean) {
        this.userProfile.UserTAndCAcceptance = value;
        this.userProfile.UserTAndCDate = new Date();
        localStorage.setItem('profile', JSON.stringify(this.userProfile));
    }

    get tsbDateTandCaccepted(): Date {
        return this.userProfile == null || this.userProfile.UserTAndCDate == null ?
            null :
            new Date(this.userProfile.UserTAndCDate);
    }

    get complianceUser(): boolean {
        return this.userProfile.Complianceuser;
    }

    get showDashboard(): boolean {
        return this.userProfile.ShowDashboard;
    }

    get governanceWorker(): boolean {
        return this.userProfile.GovernanceWorker
    }

    get isMaster(): boolean {
        if (!this.userProfile || !this.userProfile.Authorised) return false;
        return this.userProfile.IsMaster;
    }

    get hasPolicyManager(): boolean {
        if (!this.userProfile || !this.userProfile.Authorised) return false;
        return this.userProfile.HasPM
    }

    get hasComplianceStandard(): boolean {
        if (!this.userProfile || !this.userProfile.Authorised) return false;
        return this.userProfile.HasTCS;
    }

    get showNCPurchaseOption(): boolean {
        return this.userProfile && this.userProfile.ShowNCPurchaseOption;
    }

    get marketingDomain(): string {
        if (!this.userProfile || !this.userProfile.Authorised) return "";
        return this.userProfile.MarketingDomain;
    }

    get requiresPasswordChange(): boolean {
        if (!this.userProfile) {
            return false;
        }

        return this.userProfile.RequiresPasswordChange;
    }
    set requiresPasswordChange(value: boolean) {
        if (this.userProfile) {
            this.userProfile.RequiresPasswordChange = value;
        }
    }

    get userId(): number {

        if (!this.userProfile) return 0;

        return this.userProfile.UserId;
    }

    get enviroment(): string {

        if (!this.userProfile) return "";

        return this.userProfile.Enviroment;
    }

    public hasRole(role: string): boolean {

        if (!this.userProfile || this.userProfile.UserRoleClaims == undefined) return false;

        let roles: string[] = this.userProfile.UserRoleClaims.split(',');
        return roles.includes(role);

        /*let roles: string[] = _userRoleClaims.split(',');
        return roles.includes(role);*/
        
    }
    get newFeaturePopupViewed(): boolean {

        if (this.userProfile && this.userProfile.NewFeaturePopupViewed) return true;

        return false;
    }
    set newFeaturePopupViewed(value: boolean) {

        this.userProfile.NewFeaturePopupViewed = value; 
        localStorage.setItem('profile', JSON.stringify(this.userProfile));
    }

    get NPSLastQueried() {
        return this.userProfile ? this.userProfile.NPSLastQueried : null;
    }
    set NPSLastQueried(value: number) {
        this.userProfile.NPSLastQueried = value;
        localStorage.setItem('profile', JSON.stringify(this.userProfile));
    }

    get NPSDialogueShow() {
        return this.userProfile ? this.userProfile.NPSDialogueShow : null;
    }
    set NPSDialogueShow(value: boolean) {
        this.userProfile.NPSDialogueShow = value;
        localStorage.setItem('profile', JSON.stringify(this.userProfile));
    }

    public GetCurrentUser(): Observable<any> {
        return this.http.get('/api/login/GetCurrentUser');
    }

    private initializeMigrationBanner(): void {
        const backendValue = this.userProfile?.ShowMigrationBanner;
        const lastDismissed = localStorage.getItem('migrationBannerLastDismissed');
        const shouldShow = backendValue && (!lastDismissed || new Date().getTime() - parseInt(lastDismissed, 10) > 86400000);
            
        this.showMigrationBannerSubject.next(shouldShow);
    }

    public dismissMigrationBanner(): void {
        localStorage.setItem('migrationBannerLastDismissed', Date.now().toString());
        this.showMigrationBannerSubject.next(false);
    }

    get ShowMigrationBanner$() {
        return this.showMigrationBannerSubject.asObservable();
    }

}
