import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { AuthService } from '../../../guards/auth.service';
import { ManageCookiesComponent } from '../manage-cookies/manage-cookies.component';

@Component({
    selector: 'app-cookie-acceptance-popup',
    templateUrl: './cookie-acceptance-popup.component.html',
    styleUrls: ['./cookie-acceptance-popup.component.scss'],
    providers: [ManageCookiesComponent]
})
export class CookieAcceptancePopupComponent implements OnInit {
    @ViewChild('acceptCookies') acceptCookiesElement: ElementRef;

    constructor(public dialogRef: MatDialogRef<CookieAcceptancePopupComponent>,
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router) { }

    ngOnInit(): void {
    }

    acceptCookies() {
        Cookie.set('cookie_policy_acceptance', 'all', 365);
        this.authService.cookiePreferencesSetSubject.next(true);
        this.dialogRef.close();
    }

    manageCookies() {
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(() => {
            this.dialog.open(ManageCookiesComponent, { disableClose: true, panelClass: 'manage-cookies-container' });
        });
    }

    viewCookiePolicy() {
        this.router.navigateByUrl('/cookie/cookiepolicy');
    }

    viewPrivacyPolicy() {
        this.router.navigateByUrl('/privacypolicy');
    }
}
