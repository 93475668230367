
import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser'
import { FormGroup, FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard/dashboard.service';
import { AuthService } from '../../guards/auth.service';

@Component({
    selector: 'dashboard-content',
    templateUrl: './dashboard-content.component.html',
    styleUrls: ['./dashboard-content.component.css'],
    providers: [DashboardService]
})
export class DashboardContentComponent implements OnInit {
    Link: any;
    User: any;
    UserRoles: any;
    Watchlists: any;
    NFHRequests: any;
    TasksDue: any;
    ComingSoon: any;
    UpdatesInProgress: any;


    constructor(
        private dashboardService: DashboardService,
        private route: ActivatedRoute,
        private titleService: Title,
        private meta: Meta,
        private router: Router,
        private authService: AuthService
    ) {
        titleService.setTitle("Dashboard | TheSchoolBus");
        meta.updateTag({ content: "TheSchoolBus dashboard" }, "name='description'");
    }

    ngOnInit(): void {
        this.dashboardService.getUserTasksDue()
            .subscribe(result => {
                this.TasksDue = result;
            });

        this.dashboardService.getUserWatchlist()
            .subscribe(result => {
                this.Watchlists = result;
            });

        this.dashboardService.getUserRoles()
            .subscribe(result => {
                this.UserRoles = result;
            });

        this.dashboardService.getUser()
            .subscribe(result => {
                this.User = result;
                if (this.User.Master || this.User.Type == 1) {
                    this.dashboardService.getNFHRequeats()
                        .subscribe(result => {
                            this.NFHRequests = result;
                        });
                }
            });

        this.dashboardService.getFundingLink()
            .subscribe(result => {
                this.Link = result;
            });

        this.dashboardService.getComingSoon()
            .subscribe(result => {
                this.ComingSoon = result;
            });

        this.dashboardService.getUpdatesInProgress()
            .subscribe(result => {
                this.UpdatesInProgress = result;
            });
    }

    showHome(): void {
        this.dashboardService.updateShowDashboard(false)
            .subscribe(x => {
                this.authService.updateProfileValue('showDashboard', false);
                this.router.navigate(['/home']);
            });
    }
}
