import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../guards/auth.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent  {

    currentYear = new Date().getFullYear();

    constructor(private loc: Location, public authService: AuthService) { }

    isYork(): boolean {
        return window.location.host.toLowerCase().indexOf('york') > -1;
    }
}
