import { Component, OnInit } from '@angular/core';
import { AutologinService } from '../../auto-login/autologin.service';
import { AuthService } from '../../guards/auth.service';

@Component({
    selector: 'header-logo',
    templateUrl: './header-logo.component.html',
    styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit {

    constructor(private autologinService: AutologinService, private authService: AuthService) { }

    ngOnInit(): void {
    }
}
