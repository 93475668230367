import { Component, Input, Output, OnInit, OnChanges, EventEmitter, KeyValueDiffers } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'ngtable',
    templateUrl: './ngtable.component.html',
    styleUrls: ['./ngtable.component.css'],
})

export class NgTableComponent implements OnInit {
    //Work in PROGRESS
    @Input() columns: any;
    @Input() model: Array<any>;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() error: string;
    @Input() type: string;
    @Input() disabled: boolean;
    tableHeaders: any;
    sliceFrom: number = 0;
    sliceUntil: number = 10;
    //Emit the event change
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    focuslabel: boolean = false;

    //Check for change
    changeModel(change: any) {
        this.model = change;
        this.modelChange.emit(change);
    }
    //Error Checker
    checkError = function () {
        return this.error && this.error.length > 0 ? true : false;
    }

    removeRecord = function (record: number) {
        this.onRemove.emit([record]);
    }

    modelCheck = function () {
        return this.model == undefined || this.model == "" ? false : true;
    }

    ngOnInit(): void{
        let i = 0;
        let stringarray = new Array<string>();
        for (let property in this.model[0])
        {
            stringarray.push(property);
            i++;
        }
        this.tableHeaders = stringarray;
        
    
    }


}

