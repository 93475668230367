<div *ngIf="!isYork()" class="footer-v1">
    <div class="footer">
        <div class="container">
            <br>

            <div class="text-center">
                <ul class="list-unstyled" style="font-size:11pt">
                    <li><a routerLink="editorialpolicy">Editorial policy </a><i class="bottombar-divider"></i></li>
                    <li><a routerLink="terms">Terms &amp; conditions </a><i class="bottombar-divider"></i></li>
                    <li><a routerLink="privacypolicy">Privacy policy </a><i class="bottombar-divider"></i></li>
                    <li><a routerLink="cookie/cookiepolicy">Cookie policy </a><i class="bottombar-divider"></i></li>
                    <li><a routerLink="/contact-us">Contact Us</a><i class="bottombar-divider"></i></li>
                    <li>Copyright 2013-{{currentYear}} all rights reserved. TheSchoolBus.<i class="bottombar-divider"></i></li>
                </ul>
                <p class="small" style="font-size:10pt">The National College, Isaacs Building, 4 Charles Street, Sheffield, S1 2HS | Contact number: 01625464420 | Registered Number: 8368437</p>

                <div class="social-follow">
                    <a class="social-icons" href="https://www.facebook.com/theschoolbusuk" target="_blank">
                        <i class="fa fa-facebook"></i>
                    </a>

                    <a class="social-icons" href="https://twitter.com/theschoolbusuk" target="_blank">
                        <i class="fa fa-twitter"></i>
                    </a>

                    <a class="social-icons" href="https://www.linkedin.com/company/theschoolbusuk/" target="_blank">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </div>

                <a style="float:right">{{authService.enviroment}}</a>
            </div>


        </div>
    </div>
</div>
