import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NpsCollectionModalComponent } from './nps-collection-modal/nps-collection-modal.component';
import { NpsCollectionComponent } from './nps-collection/nps-collection.component';
import { NpsCollectionService } from './nps-collection.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AccountService } from '../account/account.service';

@NgModule({
    declarations: [
        NpsCollectionModalComponent,
        NpsCollectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatCheckboxModule
    ],
    exports: [
        NpsCollectionComponent
    ],
    providers: [
        NpsCollectionService,
        AccountService
    ]
})
export class NpsCollectionModule {
}
