import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../guards/auth.service';
import { Location } from '@angular/common';
import { NpsCollectionService } from '../nps-collection.service';
import { NpsCollectionModalComponent } from '../nps-collection-modal/nps-collection-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'nps-collection',
    template: ''
})
export class NpsCollectionComponent implements OnInit {

    constructor(
        private auth: AuthService,
        private loc: Location,
        private npsCollectionService: NpsCollectionService,
        private dialogue: MatDialog) { }

    ngOnInit(): void {
        this.auth.loggedIn$.subscribe(() => {
            if (this.auth.NPSDialogueShow && this.validUrl() && !this.isMobile()) {
                setTimeout(() => {
                    if (this.dialogue.openDialogs.length == 0) {
                        this.dialogue.open(NpsCollectionModalComponent, {
                            disableClose: true,
                            hasBackdrop: false,
                            panelClass: 'nps-modal',
                            autoFocus: false,
                            width: '100% !important',
                            position: {
                                bottom: '0'
                            },
                        })
                    }
                }, 10000)
            }
        });
    }

    validUrl(): boolean {
        return (
            this.loc.path() != '/logout' &&
            this.loc.path() != '/login' &&
            this.loc.path() != '/account/changepassword'
        );
    }

    isMobile(): boolean {
        return window.screen.width < 1024;
    }
}
