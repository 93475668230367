import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'sb-card-header',
    templateUrl: './sb-card-header.component.html',
    
    styleUrls: ['./sb-card-header.component.css'],

})
export class SbCardHeaderComponent {


}
