import { Component, Input } from '@angular/core';

@Component({
    selector: 'sidepanel',
    templateUrl: './sidepanel.container.component.html',
    styleUrls: ['./sidepanel.container.component.scss']

})
export class SidepanelContainer {
    @Input() title: String;
    @Input() h1title: String;
}
