import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
} from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let url: string = state.url;

        return this.checkAdmin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {

        let url = `/${route.path}`;

        return this.checkAdmin(url);
    }

    checkAdmin(url: string): boolean {

        if (this.authService.authenticated && this.authService.hasRole("HCSS")) {

            return true;
        }
        else {

            if (!this.authService.authenticated) {

                this.router.navigate(['/login']);
            } else {

                this.router.navigate(['/home']);
            }

        }
        return false;

    }
}
