<div id="loader" *ngIf="loaded">
    <h1>Your cookie choices</h1>
    <div id="userChoices">
        <div id="essentialsMessage">
            <mat-slide-toggle [disabled]="true" [(ngModel)]="alwaysTrue"></mat-slide-toggle>
            <h2>Essential cookies only</h2>
        </div>
        <p>Just the essential cookies to use the site.  This may give a stripped down experience and may make it harder for us to diagnose certain issues</p>
        <mat-slide-toggle [(ngModel)]="analyticsCookies" name="analyticsCookies"><h2>Allow analytics cookies</h2></mat-slide-toggle>
        <p>These show us things like where and when users are and how they are using our site but doesn't identify individual users</p>
        <mat-slide-toggle [(ngModel)]="marketingCookies" name="marketingCookies"><h2>Allow marketing cookies</h2></mat-slide-toggle>
        <p>These allow us to occasionally show you information on current and new products that may help you get the job done</p>
    </div>
    <div class="bottom-buttons">
        <div class="btn button btn-primary" (click)="saveChoices()">Save choices</div>
    </div>
</div>
