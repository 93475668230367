<div class="btn-group margin-10" *ngIf="model && model.length > 0">
    <button class="btn btn-page" (click)="perviousPage()"><<</button>
    <button class="btn btn-page" [ngClass]="{'active' : currentPage == page}" *ngFor="let page of pagenumbers | slice:limitBegin():limit" (click)="changePage(page)"> {{page}} </button>
    <button class="btn btn-page" (click)="nextPage()">>></button>
</div>
 <div class="btn-group margin-10 floater" *ngIf="model && model.length > 0">
    <button class="btn btn-page active" [ngClass]="{'active' : pagination == paginationDefault}" (click)="repaginate(paginationDefault)">{{paginationDefault}}</button>
    <button class="btn btn-page" [ngClass]="{'active' : pagination == paginationDefault * 2}" (click)="repaginate(paginationDefault * 2)"> {{paginationDefault * 2}} </button>
    <button class="btn btn-page" [ngClass]="{'active' : pagination == paginationDefault * 10}" (click)="repaginate(paginationDefault * 10)">{{paginationDefault * 10}}</button>
</div>
