<div class="margin-top-bottom-20 col-md-12" [formGroup]="preferencesForm">
    <div class="setup">
        <h2 class="title">Your job role</h2>
        <p class="intro-text">Your primary and secondary job roles will tailor your dashboard to show the most important articles in relation to your role.</p>
        <div class="col-md-6">
            <mat-form-field>
                <mat-select placeholder="My primary job role is" formControlName="primaryRoleControl" required [disabled]="formDisabled">
                    <mat-option *ngFor="let primaryRole of primaryRoles" [value]="primaryRole.PrimaryJobRoleId">{{primaryRole.PrimaryJobRole}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field>
                <mat-select placeholder="My secondary roles are" formControlName="secondaryRoleControl" (selectionChange)="optionLimiter($event, 'secondaryRoleControl', 3)" multiple required [disabled]="formDisabled">
                    <mat-option *ngFor="let secondaryRole of seconaryRoles" (onSelectionChange)="optionChanged($event)" [value]="secondaryRole.SecondaryJobRoleId">{{secondaryRole.SecondaryJobRole}}</mat-option>
                </mat-select>
                <mat-hint>Max 3 roles</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="setup">
        <h2>Your interests</h2>
        <p class="intro-text">Your interests will allow us to feature content we think you'll like to see.</p>
        <div class="col-md-6">
            <mat-form-field>
                <mat-select placeholder="I am really interested in" formControlName="primaryInterestsControl" (selectionChange)="optionLimiter($event, 'primaryInterestsControl', 2)" multiple required [disabled]="formDisabled">
                    <mat-option *ngFor="let primaryInterest of primaryInterests" (onSelectionChange)="optionChanged($event)" [value]="primaryInterest.PrimaryInterestId">{{primaryInterest.PrimaryInterest}}</mat-option>
                </mat-select>
                <mat-hint>Max 2 interests</mat-hint>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field>
                <mat-select placeholder="I am quite interested in" formControlName="secondaryInterestsControl" (selectionChange)="optionLimiter($event, 'secondaryInterestsControl', 5)" multiple required [disabled]="formDisabled">
                    <mat-option *ngFor="let secondaryInterest of secondaryInterests" (onSelectionChange)="optionChanged($event)" [value]="secondaryInterest.SecondaryInterestId">{{secondaryInterest.SecondaryInterest}}</mat-option>
                </mat-select>
                <mat-hint>Max 5 interests</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="setup">
        <button type="button" class="btn btn-warning edit-user-preferences" (click)="setEditablePreferences(true)" style="float: right; display: none;">
            Edit User Settings
        </button>
        <button type="button" class="btn btn-primary update-user-preferences" (click)="updatePreferences()" [disabled]="preferencesForm.invalid" style="float: right;">
            Save
        </button>
        <success-message *ngIf="showSuccessMessage"></success-message>
    </div>
    <div class="alert alert-success" role="alert" *ngIf="changesSaved && preferencesForm.pristine && !startFormDisabled" style="margin-top: 140px;">Save Successful</div>
</div>
