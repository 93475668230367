
import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser'
import { MatDialog } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.css'],
    providers: [DashboardService]
})
export class UserPreferencesComponent implements OnInit {
    primaryRoles: any;
    seconaryRoles: any;
    primaryInterests: any;
    secondaryInterests: any;
    changesSaved: boolean = false;
    optionSelected: number;
    @Input() startFormDisabled: boolean = false;
    formDisabled: boolean = false;
    showSuccessMessage: boolean = false;

    constructor(
        private dashboardService: DashboardService,
        private route: ActivatedRoute,
        private titleService: Title,
        private meta: Meta,
        private router: Router,
    ) {
        titleService.setTitle("Task Lists | TheSchoolBus");
        meta.updateTag({ content: "TheSchoolBus add task" }, "name='description'");
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
    }

    preferencesForm = new FormGroup({
        primaryRoleControl: new FormControl(),
        secondaryRoleControl: new FormControl(),
        primaryInterestsControl: new FormControl(),
        secondaryInterestsControl: new FormControl()
    });

    optionLimiter(event: any, valueContainer: any, optionLimit: number): void {
        let controlValue = this.preferencesForm.value[valueContainer];
        let selectedValue = this.optionSelected;
        if (controlValue.length > optionLimit) {
            this.preferencesForm.get(valueContainer).setValue(
                controlValue.filter(function (x) { return x != selectedValue; }));
        }
    }

    optionChanged(event: any): void {
        this.optionSelected = event.source.value;
    }

    updatePreferences(): void {
        let preferences = {
            PrimaryRole: this.preferencesForm.value.primaryRoleControl,
            SecondaryRoles: this.preferencesForm.value.secondaryRoleControl,
            PrimaryInterests: this.preferencesForm.value.primaryInterestsControl,
            SecondaryInterests: this.preferencesForm.value.secondaryInterestsControl,
            ShowDashboard: location.pathname.indexOf('dashboard') > -1
        };

        this.dashboardService.updatePreferences(preferences)
            .subscribe(response => {
                this.changesSaved = true;
                this.setupForm(response);
                if (this.startFormDisabled) {
                    this.conditionallyDisableForm(true);
                    this.showSuccessMessage = true;
                    window.setTimeout(() => {
                        this.showSuccessMessage = false;
                    }, 3000);
                }

                this.router.navigateByUrl('/mydashboard');
            });
    }

    setupForm(values: any): void {
        this.primaryRoles = values.PrimaryJobRoles;
        this.seconaryRoles = values.SecondaryJobRoles;
        this.primaryInterests = values.PrimaryInterests;
        this.secondaryInterests = values.SecondaryInterests;

        this.preferencesForm.get('primaryRoleControl').setValue(values.PrimaryUserJobRole.PrimaryJobRoleId);
        this.preferencesForm.get('secondaryRoleControl').setValue(values.SecondaryUserJobRoles);
        this.preferencesForm.get('primaryInterestsControl').setValue(values.PrimaryUserInterests);
        this.preferencesForm.get('secondaryInterestsControl').setValue(values.SecondaryUserInterests);
        this.preferencesForm.markAsPristine();
    }

    ngOnInit() {
        this.dashboardService.getUserPreferences().subscribe(values => this.setupForm(values));
        this.conditionallyDisableForm(this.startFormDisabled);
    }

    conditionallyDisableForm(isDisabled: boolean): void {
        this.formDisabled = isDisabled;

        if (isDisabled) {
            window.setTimeout((x) => {
                let selectParts = document.querySelectorAll('.mat-form-field-type-mat-select *');
                let underlines = document.querySelectorAll('.mat-form-field-underline');
                let selects = document.querySelectorAll('.mat-form-field-type-mat-select *');
                let editButtons = document.getElementsByClassName('edit-user-preferences');
                let updateButtons = document.getElementsByClassName('update-user-preferences');

                for (let i = 0; i < selectParts.length; i++) {
                    (<HTMLElement>selectParts[i]).style.color = '#cbdddc';
                }

                for (let j = 0; j < underlines.length; j++) {
                    (<HTMLElement>underlines[j]).style.backgroundColor = '#cbdddc';
                }

                for (let k = 0; k < selects.length; k++) {
                    (<HTMLElement>selects[k]).style.color = '#cbdddc';
                }

                for (let l = 0; l < editButtons.length; l++) {
                    (<HTMLElement>editButtons[l]).style.display = 'block';
                }

                for (let m = 0; m < updateButtons.length; m++) {
                    (<HTMLElement>updateButtons[m]).style.display = 'none';
                }
            }, 20)
        }
    }

    setEditablePreferences(isEditable: boolean): void {
        let editButtons = document.getElementsByClassName('edit-user-preferences');
        let updateButtons = document.getElementsByClassName('update-user-preferences');

        for (let i = 0; i < editButtons.length; i++) {
            (<HTMLElement>editButtons[i]).style.display = 'none';
        }

        for (let j = 0; j < updateButtons.length; j++) {
            (<HTMLElement>updateButtons[j]).classList.add('btn-warning');
            (<HTMLElement>updateButtons[j]).classList.remove('btn-primary');
            (<HTMLElement>updateButtons[j]).style.display = 'block';
        }

        [].slice.call(document.getElementsByClassName('job-role-container')).map(x => <HTMLElement>x).forEach(container => {
            container.style.backgroundColor = '#347186';
        });

        // enable selects
        this.formDisabled = false;
    }
}
