<table style="width:100%;border-collapse: separate;border-spacing: 0 10px;">
    <tr class="center">
        <th class="center" *ngFor="let head of columns">
            {{head.title}}
        </th>
    </tr>

</table>
<div class="margin-10">
    <pagination [model]="model" [(sliceFrom)]="sliceFrom" [(sliceUntil)]="sliceUntil"></pagination>
</div>