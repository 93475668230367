<div>
    <section class="container login">
        <div class="row">
            <div class="col-md-5" style="padding-top:30px;">

                <sidepanel [h1title]="title">
                    <div class="wrapper">
                        <p *ngIf="mode == 'pinexpired'">This temporary password has expired. We have sent a new one to your inbox - please activate your account by logging in with the new temporary password.</p>
                        <p *ngIf="mode == 'activationexpired'">This link has now expired and you will need to verify your email address in order to activate your account.<br />To do this, we have sent another email to your inbox - please follow the instructions in this email to verify your email address.</p>
                        <p *ngIf="mode == 'activationerror'">There seems to be an error with the activation link. Please make sure that you have correctly copy and pasted the link from your email, or contact us for further help.</p>
                        <p *ngIf="mode == 'userlocked'">You have exceeded the number of failed log in attempts and your account is now locked for five minutes. If you have forgotten your password, you can reset this via the 'Forgot password?' link.</p>
                    </div>
                    <div class="notmember">
                        <div class="bt">
                            <a routerLink="/login" class="btn btn-yellow signup" style="margin-right:20px;">sign in now</a>
                        </div>
                        <div class="or">
                            <span style="color:#686464 !important">or</span>
                        </div>
                        <div class="bt">
                            <a target="_self" href="http://hub4leaders.co.uk/" class="btn btn-yellow findmore">find out more</a>
                        </div>
                    </div>
                </sidepanel>
            </div>
            <div class="col-md-7 hidden-xs">
                <div class="right">
                    <img src="/Img/core/login/locked.png" style="margin-top: 70px" />
                </div>
            </div>
        </div>
    </section>

</div>
