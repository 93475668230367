<sidepanel *ngIf="popular && popular.length > 0">
    <h4>Popular Resources</h4>
    <div *ngFor="let object of popular">
        <a [routerLink]="'/' + object.Url" >
            <div class="side-box">
                <div class="type-name">{{object.TypeName}}</div>
                <div>{{object.Title}}</div>
                <img src="../../../Img/icons/material/arrow_forward_navy.svg" />
            </div>
        </a>
    </div>
</sidepanel>
