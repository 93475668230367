<div *ngIf="articles.length > 0" title="Related content">

    <h4 class="headerunderline">Related content</h4>

    <div style="margin-bottom: 5px;" *ngFor="let article of articles">
        <div style="margin-top: 0px;">
            <div class="sideBox  mat-elevation-z2">
                <a href="{{article.ArticleUrl}}">
                    <div class="sideBoxIcon {{article.ArticleTypeClass}}"></div>
                    <div class="textwrap">{{article.Title}}</div>
                </a>
            </div>

        </div>
    </div>
</div>
