import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'content-blocker',
  templateUrl: './content-blocker.component.html',
  styleUrls: ['./content-blocker.component.scss']
})
export class ContentBlockerComponent implements OnInit {
    @Input() leftContent = {
        textOne: '',
        textTwoOrange: '',
        textThree: '',
        textFourBold: ''
    }

    constructor() { }

    ngOnInit(): void {

    }

}
