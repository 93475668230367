import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class WatchListService extends BaseService {
    constructor(http: HttpClient) {
        super(http);
    }
    
    getUserReadingList(): Observable<any> {
        return this.http.get('api/account/GetUserReadingList').pipe(
            catchError(this.handleError('getUserReadingList'))
        );
    }  

    removeWatchListRecord(id: number): Observable<any> {
        return this.http.post('api/account/RemoveReadingListRecord/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('removeReadingListRecord'))
        );
    }

    removeTopicRecord(id: number): Observable<any> {
        return this.http.post('api/account/RemoveTopicRecord/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('removeTopicRecord'))
        );
    }

    removeTopicCategoryRecord(id: number): Observable<any> {
        return this.http.post('api/account/RemoveTopicCategoryRecord/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('removeTopicRecord'))
        );
    }

    // new watch list services **Tech debt - adapt other components to use these and refactor this class to become WatchListService...**

    getUserWatchList(): Observable<any> {
        return this.http.get('api/WatchList/GetUserWatchList').pipe(
            catchError(this.handleError('GetUserWatchList'))
        );
    }

    addToWatchList(id: number, contentType: string): Observable<any> {
        return this.http.post<any>(
            `/api/WatchList/AddToWatchList?contentId=${id}&watchListContentType=${contentType}`, httpOptions).pipe(
            catchError(this.handleError('AddToWatchList'))
            );
    }

    removeFromWatchList(id: number, contentType: string): Observable<any> {
        return this.http.post<any>(
            `api/WatchList/RemoveFromWatchList?contentId=${id}&watchListContentType=${contentType}`, httpOptions).pipe(
            catchError(this.handleError('RemoveFromWatchList'))
        );
    }
}
