import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { AutologinService } from '../../auto-login/autologin.service';
import { AuthService } from '../../guards/auth.service';
import { HeaderService } from '../header.service';

@Component({
  selector: 'header-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input() toolbarData: Array<any>;
    memberText: string;
    memberButtonUrl: string;
    showMemberButton: boolean = true;
    showNoFinalSeparator: string;
    pwaReady: any;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private headerService: HeaderService,
        private authService: AuthService,
        private autologinService: AutologinService,) { }

    ngOnInit(): void {
        this.setButtonVisibility();

        this.authService.loggedIn$.subscribe(() => {
            this.setButtonVisibility();
        });

        window.addEventListener('beforeinstallprompt', event => {
            event.preventDefault();
            this.pwaReady = event;
        });
    }

    installPWA(): void {
        this.pwaReady.prompt();
    }

    setButtonVisibility(): void {
        if (!this.authService.authenticated) {
            this.memberText = 'BECOME A MEMBER';
        } else {
            this.showMemberButton = false;
        }
    }

    ngAfterViewInit() {
        this.setupChildMenuLinks();
    }

    setupChildMenuLinks() {
        const menuItems = this.elementRef.nativeElement.querySelectorAll('.toolbar-items');
        menuItems.forEach((item, index) => {
            this.toolbarData[index].childMenu = item.querySelector('.menu-container');
            this.toolbarData[index].parentIcon = item.querySelector('.material-image');
        });
    }

    openMenu(parentMenuItem, index) {
        this.setupChildMenuLinks();
        if (parentMenuItem.childMenu.className.includes('hidden-menu')) {
            this.renderer.removeClass(parentMenuItem.childMenu, 'hidden-menu');
            const displayClass = index > 1 ? 'child-list-right' : 'child-list-left';
            this.renderer.addClass(parentMenuItem.childMenu, displayClass);

            let backgroundImage = parentMenuItem.parentIcon.style.backgroundImage.replace('.png', '_hover.png');
            this.renderer.setStyle(parentMenuItem.parentIcon, 'background-image', backgroundImage);
            this.renderer.addClass(parentMenuItem.childMenu.parentNode, 'hovered');
        }
    }

    closeMenu(parentMenuItem, index) {
        this.renderer.addClass(parentMenuItem.childMenu, 'hidden-menu');
        const displayClass = index > 1 ? 'child-list-right' : 'child-list-left';
        this.renderer.removeClass(parentMenuItem.childMenu, displayClass);

        let backgroundImage = parentMenuItem.parentIcon.style.backgroundImage.replace('_hover', '');
        this.renderer.setStyle(parentMenuItem.parentIcon, 'background-image', backgroundImage);

        this.renderer.removeClass(parentMenuItem.childMenu.parentNode, 'hovered');
    }

    toggleMenu(parentMenuItem, index) {
        if (parentMenuItem.childMenu.classList.contains('hidden-menu')) {
            this.openMenu(parentMenuItem, index);
        } else {
            this.closeMenu(parentMenuItem, index);
        }
    }

    getLink(rawLink): string {
        return rawLink?.split('?')[0];
    }

    getQueryParams(rawLink): any {
        if (rawLink?.split('?').length > 1) {
            let rawParams = rawLink.split('?')[1].split('&');
            let result = {};
            rawParams.forEach(x => {
                let keyValue = x.split('=');
                result[keyValue[0]] = keyValue[1];
            });

            return result;
        } else {
            return;
        }
    }

    materialImage(iconRef) {
        return `url('../../../../Img/icons/material/${iconRef}.png')`;
    }

    tncAutoLogin(): void {
        if (this.authService.loggedIn) {
            this.autologinService.getAutoLoginString('/pages/the-school-bus')
                .subscribe(autologinTarget => window.location.assign(autologinTarget));
        } else {
            window.location.assign('https://nationalcollege.com/pages/the-school-bus');
        }
    }
}
