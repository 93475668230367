import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';

export class BaseService {
    constructor(protected http: HttpClient) {
    }

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    httpEmptyOptions = {
        headers: new HttpHeaders()
    };

    /**
     * 
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.http.post<any>('/api/Base/LogClientError', JSON.stringify(error), this.httpOptions).subscribe();

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
