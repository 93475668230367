
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'relatedcontent',
    templateUrl: './relatedcontent.component.html',
    styleUrls: ['./relatedcontent.component.css']
})
export class RelatedContentComponent implements OnInit {

    @Input() articles: any;

    ngOnInit(): void {
    }
}
