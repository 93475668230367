<div class="my-account-wrapper">
    <div *ngIf="loggedIn" class="my-account">
        <div class="account-icon" (mouseover)="showAccountMenu()" (mouseleave)="hideAccountMenu()">
            <span class="account-icon-image"></span>
        </div>
        <div id="account-menu" class="my-account-list" [ngClass]="{'hidden-menu': hideMenu}" (mouseover)="showAccountMenu()" (mouseleave)="hideAccountMenu()">
            <div *ngFor="let account of myAccount">
                <div class="account-item" *ngIf="account.UseRouterLink == true" (click)="clickedLink(account.Url, true)">
                    <a [routerLink]="account.Url" (click)="hideAccountMenu()">
                        {{account.Name}}
                        <span *ngIf="isDocumentsToAction(account.Url) && documentsToAction() > 0" class="fa-stack notifications-right">
                            <span class="fa fa-circle fa-stack-2x">
                            </span>
                            <span class="fa-stack-1x">
                                {{documentsToAction()}}
                            </span>
                        </span>
                    </a>
                </div>
                <div class="account-item" *ngIf="account.UseRouterLink == false" (click)="clickedLink(account.Url, false)">
                    <a [href]="account.Url" target="_blank" (click)="hideAccountMenu()">
                        {{account.Name}}
                    </a>
                </div>
            </div>
            <div class="account-item no-border" (click)="clickedLink('/logout', true)">
                <a [routerLink]="'/logout'">Log Out</a>
            </div>
        </div>
    </div>
    <div *ngIf="!loggedIn">
        <a class="login-text my-account" [routerLink]="'/login'" [queryParams]="{redirect: location.path().indexOf('login') >= 0 ? '/home' : location.path()}">
            LOG IN
        </a>
    </div>
</div>
