import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class ArticleService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getArticle(id: number): Observable<any> {
        return this.http.get('/api/article/getarticle/' + id).pipe(
            catchError(this.handleError('getarticle'))
        );
    }

    getRelatedArticles(articleId: number): Observable<any> {
        return this.http.get('/api/article/getRelatedArticles/' + articleId).pipe(
            catchError(this.handleError('getRelatedArticles'))
        );
    }

    getArticleGroupedTasks(articleId: number): Observable<any> {
        return this.http.get('/api/article/getArticleGroupedTasks/' + articleId).pipe(
            catchError(this.handleError('getArticleGroupedTasks'))
        );
    }

    getNewAndUpdatedArticlePageData(): Observable<any> {
        return this.http.get('/api/articleupdates/GetNewAndUpdatesPageData/').pipe(
            catchError(this.handleError('getNewAndUpdatedArticlePageData'))
        );
    }

    getNewAndUpdatedArticles(id: number): Observable<any> {
        return this.http.get('/api/articleupdates/getnewandupdates/' + id).pipe(
            catchError(this.handleError('getnewandupdates'))
        );
    }

    getNewAndUpdatedArticlesByType(id: number): Observable<any> {
        return this.http.get('/api/articleupdates/getnewandupdatesbytype/' + id).pipe(
            catchError(this.handleError('getnewandupdatesbytype'))
        );
    }

    getNewAndUpdatedArticlesByFocusPage(id: number): Observable<any> {
        return this.http.get('/api/articleupdates/GetNewAndUpdatesByFocusArea/' + id).pipe(
            catchError(this.handleError('GetNewAndUpdatesByFocusArea'))
        );
    }

    getMostPopularArticles(id: number): Observable<any> {
        id = id == null || id == undefined ? -1 : id;
        
        return this.http.get('/api/article/mostpopular/' + id).pipe(
            catchError(this.handleError('mostpopular'))
        );
    }

    getMostPopularArticlesByType(id: number): Observable<any> {
        return this.http.get('/api/article/mostpopularbytype/' + id).pipe(
            catchError(this.handleError('mostpopular'))
        );
    }

    getMostPopularArticlesByFocusArea(focusAreaId: number): Observable<any> {
        return this.http.get('/api/article/mostpopularbyfocusarea/' + focusAreaId).pipe(
            catchError(this.handleError('mostpopularbyfocusarea'))
        );
    }

    getNfhArticlesByFocusArea(focusArea: string): Observable<any> {
        return this.http.get(`/api/articlelist/GetNfhArticlesByFocusArea/?focusArea=${focusArea}`).pipe(
            catchError(this.handleError('GetNfhArticlesByFocusArea'))
        );
    }

    HasUserSubscription(id: number): Observable<any> {
        return this.http.get('/api/WatchList/HasUserSubscription/' + id).pipe(
            catchError(this.handleError('HasUserSubscription'))
        );
    }

    SaveArticleFeedback(userWrapper: any): Observable<any> {
        return this.http.post<any>('/api/article/SaveArticleFeedback', userWrapper, this.httpOptions).pipe(
            catchError(this.handleError('SaveArticleFeedback'))
        );
    }

    SaveRecordDownload(id: any): Observable<any> {
        return this.http.post<any>('/api/article/RecordDownload/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('RecordDownload'))
        );
    }

    RecordPreview(id: any): Observable<any> {
        return this.http.post<any>('/api/article/RecordPreview/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('RecordPreview'))
        );
    }

    RecordPreviewDownload(id: any): Observable<any> {
        return this.http.post<any>('/api/article/RecordPreviewDownload/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('RecordPreviewDownload'))
        );
    }

    AddToReadingList(id: number): Observable<any> {
        return this.http.post<any>('/api/article/AddToReadingList/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('AddToReadingList'))
        );
    }

    addTaskToUserList(id: number): Observable<any> {
        return this.http.post('/api/TaskUserTask/Add/' + id, this.httpOptions).pipe(
            catchError(this.handleError('addTaskToGroup'))
        );
    }

    sendReviewRequest(feedback: any): Observable<any> {
        return this.http.post('/api/article/SendReviewRequestEmail', feedback, this.httpOptions).pipe(
            catchError(this.handleError('saveReviewRequest'))
        );
    }
}
