import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
} from '@angular/router';

import { LoginService } from '../login/login.service'
import { AuthService } from './auth.service';


@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router,
        private loginService: LoginService,
        private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {

        let url = `/${route.path}`;
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {

        if (this.authService.authenticated) {

            return true;
        }
        else {

            this.loginService.redirectUrl = url;
            this.router.navigate(['/login']);
        }
        return false;
    }
}
