
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationExtras} from '@angular/router';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { LoginService } from './login.service';
import { AuthService } from '../guards/auth.service';
import { LogoutService } from './logout.service';

declare var ga: any;

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LogoutService]
})
export class LoginComponent implements OnInit {
   
    details: any = {
        email: '',
        password: '',
        token: '',
        tokenOld: '',
        requiresRedirect: true,
        url: ''
    }

    actionInProgress: boolean = false;

    errorMessage: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private logoutService: LogoutService,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
        private authService: AuthService,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2
    ) {
    }


    ngOnInit(): void {
        let iframes: any = document.getElementsByTagName('iframe');
        if ([...iframes].map(x => x.src).filter(x => x.indexOf('thecompliancestandard') > -1).length == 0) {
            this.authService.logout();
            this.authService.logoutTCSAndCC();
            this.logoutService.logout(true);
        }

        const title = 'Login - TheSchoolBus';
        const description = 'Log in to your TheSchoolBus account to access articles, templates and compliance tools.';
        const domain = window.location.origin;

        this.titleService.setTitle(title);
        this.metaService.updateTag({ content: description }, "name='description'");

        this.metaService.updateTag({ content: title }, 'property="og:title"');
        this.metaService.updateTag({ content: description }, 'property="og:description"');

        let canonical = document.querySelector('link[rel="canonical"]');
        this.renderer.setAttribute(canonical, 'href', `${domain}/login`);

        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.details.url = params['redirect'];
            this.details.redirectMode = params['redirectMode']
            this.details.token = params['token'];
            this.details.tokenOld = params['TokenID'];  // old activation
        });

        if (!this.details.url) {
            this.details.url = this.loginService.redirectUrl;
        } else {
            if (this.details.url.indexOf('loggedOut') > -1) {
                window.location.replace(`${window.location.origin}${window.location.pathname}`);
            }
        }

        if (this.details.token || this.details.tokenOld) {
            this.login();
        }
    }

    onEnter(): void {
        this.login();
    }

    login() : void {
        this.actionInProgress = true; 
        this.errorMessage = '';

        this.loginService
            .login(this.details)
            .subscribe(result => {

                if (this.authService.userId > 0) {
                    ga('set', 'userId', this.authService.userId.toString());
                }

                switch (result.status) {
                    case 0:
                        if (result.redirectUrl) {
                            if (result.redirectMode == 'window') {
                                window.location = result.redirectUrl;
                            }

                            if (result.redirectUrl == '/cpd/Home') {
                                this.router.navigateByUrl('/home');
                            } else {
                                let linkUrl = sessionStorage.getItem('linkUrl');
                                if (linkUrl != null) {
                                    if (result.userProfile.UserRoleClaims && result.userProfile.UserRoleClaims.indexOf('TrialUser') == -1) {
                                        result.redirectUrl = linkUrl;
                                    }
                                } 

                                if (result.redirectUrl.indexOf('home') > 0 && this.authService.governanceWorker) {
                                    this.router.navigateByUrl('/category/governance');
                                } else {
                                    this.router.navigateByUrl(result.redirectUrl);
                                }
                            }
                        } else if (this.loginService.redirectUrl != "") {

                            let redirect = this.loginService.redirectUrl;

                            let navigationExtras: NavigationExtras = {
                                queryParamsHandling: 'preserve',
                                preserveFragment: true
                            };

                            this.router.navigate([redirect], navigationExtras);

                        } else {
                            this.router.navigateByUrl('/home');
                        }
                        
                        break;

                    case 1:
                        this.router.navigateByUrl('login/disabled');
                        break;

                    case 2:
                        this.router.navigateByUrl('login/inactive?email=' + this.details.email);
                        break;

                    case 3:
                        this.errorMessage = "Either that email address wasn't found in our database, or you have entered an incorrect email address or password. Please double check your email address and try again.";
                        break;

                    case 4:
                        this.router.navigateByUrl('login/expired');
                        break;

                    case 5: // PinExpired
                        this.router.navigateByUrl('login/pinexpired');
                        break;

                    case 6: // ActivationExpired
                        this.router.navigateByUrl('login/activationexpired');
                        break;

                    case 7: // ActivationError
                        this.router.navigateByUrl('login/activationerror');
                        break;

                    case 8: // UserLocked
                        this.router.navigateByUrl('login/userlocked');
                        break;

                    default:
                        this.errorMessage = "Either that email address wasn't found in our database, or you have entered an incorrect email address or password. Please double check your email address and try again.";

                }

                this.actionInProgress = false;
            });
    }
}
