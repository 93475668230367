import { HttpHandler, HttpRequest, HttpInterceptor, HttpHeaderResponse, HttpSentEvent, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AuthService } from "../auth.service";
import { Observable ,  BehaviorSubject ,  of ,  throwError as _throw } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    //isRefreshingToken: boolean = false;
    //tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    //tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private authService: AuthService;

    constructor(/*private authService: AuthService*/ private injector: Injector, private router: Router) { }

    /*addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
    }*/

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {//Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        //return next.handle(this.addToken(req, this.authService.getAuthToken()))

        this.authService = this.injector.get(AuthService); // get it here within intercept

        return next.handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {}),
                catchError((err, caught) => this.handleError(err, caught))
            );
    }

    handleError<T>(err: any, caught: Observable<T>, result?: T) {

        console.log(err, caught, result);

        if (err instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>err).status) {
                case 400:
                    return this.handle400Error(err, caught);
                case 401:
                    return this.handle401Error(err, caught);
            }
        }

        return _throw(err);
    }

    handle400Error<T>(err: any, caught: Observable<T>) {

        if (err && err.status === 400 && err.error && err.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser(caught);
        }

        return _throw(err);
    }

    handle401Error<T>(err: any, caught: Observable<T>, result?: T) {

        return this.logoutUser(caught);
    }

    logoutUser<T>(caught: Observable<T>) {

        this.authService.logout();

        this.router.navigateByUrl("/login");

        return _throw(caught);
    }
}
