<sidepanel *ngIf="onWatchList && !onWatchList.NotAuthorized">
    <h4 class="headerunderline">Watch List</h4>
    <div class="select side-box" (click)="addToWatch()" *ngIf="!onWatchList.OnWatchList">
        <div class="fa fa-bell-o send"></div>
        <div class="block">Add to my watch list and notify me when this changes.</div>
    </div>
    <div>
        <a class="select side-box" [routerLink]="'/watchlist'" *ngIf="onWatchList.OnWatchList">
            <div class="fa fa-bell list"></div>
            <div class="block">You’ll be notified when this changes. Click here to view your watch list.</div>
        </a>
    </div>
</sidepanel>
