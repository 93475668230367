import { Component, Input, Output, OnInit, OnChanges, EventEmitter, KeyValueDiffers } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})

export class PaginationComponent implements OnInit, OnChanges  {

    @Input() model: any;
    @Input() sliceFrom: number;
    @Input() sliceUntil: number;
    @Input() pageChange: number;
    @Output() sliceUntilChange: EventEmitter<number>;
    @Output() sliceFromChange: EventEmitter<number>;
    


    private filter: any;
    private index: number = 5;
    private pagenumbers: Array<number> = [];
    private currentPage: number = 1;
    private pagination: number;
    private paginationDefault: number;
    private differ: any;
    private  count: number = 0;
    private limit: number = 5;

    constructor(private differs: KeyValueDiffers) {

        this.differ = differs.find({}).create();
        this.sliceUntilChange = new EventEmitter(true);
        this.sliceFromChange = new EventEmitter(true);

    }

    ngOnChanges() {

        var changes = this.differ.diff(this.model);

        if (changes)
        {
            if (this.count > 0)
            {
                this.GetPageNumbers(this.model);
                if (this.currentPage > this.pagenumbers.length) {
                    this.currentPage = this.pagenumbers.length;
                    this.changePage(this.currentPage);
                }
            }
            this.count ++;
        }
/*
        var requestPageChange = this.differ.diff(this.pageChange);
        if (requestPageChange) {
            this.currentPage = 0;

        }
        */
    }


    ngOnInit(): void {
        this.pagination = this.paginationDefault = this.sliceUntil;
        this.GetPageNumbers(this.model);
    }

    private GetPageNumbers(array: any): void {
        var t = 0;
        this.pagenumbers = [];
        
        if (!array) return;

        for (var i = 0; i < array.length; i++) {
            if ((i / this.pagination) % 1 === 0) {
                var t = t + 1;
                this.pagenumbers.push(t);
            }
        }
    }

    private changeSliceUntil(change: number): void  {
        this.sliceUntil = change;
        this.sliceUntilChange.emit(change);

    }
    private changeSliceFrom(change: number): void {
        this.sliceFrom = change;
        this.sliceFromChange.emit(change);

    }

    private changePage(pageNumber: number): void {
        if (pageNumber > this.pagenumbers.length) {
            pageNumber = this.pagenumbers.length;
        }
        if (pageNumber < 1) {
            pageNumber = 1;
        }
        this.limitShift(pageNumber);
        this.currentPage = pageNumber;
        this.changeSliceUntil(this.currentPage * this.pagination);
        this.changeSliceFrom(this.sliceUntil - this.pagination);

    }

    private limitShift(pageNumber: number): void {
        let newLimit = pageNumber + 2;
        if (newLimit < this.pagenumbers.length && newLimit > 5) {
            this.limit = pageNumber + 2;
        } else if (newLimit <= 5) {
            this.limit = 5;
        } else if (newLimit >= this.pagenumbers.length) {
            this.limit = this.pagenumbers.length;
        }
        
    } 


    private nextPage(): void {
        {
            if (this.currentPage != this.pagenumbers.length) {
                this.currentPage = this.currentPage + 1;
                this.changeSliceUntil(this.currentPage * this.pagination);
                this.changeSliceFrom(this.sliceUntil - this.pagination);
                this.limit = this.limit != this.pagenumbers.length ? this.limit + 1 : this.limit;
            }
        }
    }

    private perviousPage(): void {
        if (this.currentPage != 1) {
            this.currentPage = this.currentPage - 1;
            this.changeSliceUntil(this.currentPage * this.pagination);
            this.changeSliceFrom(this.sliceUntil - this.pagination);
            this.limit = this.limitBegin() == 0? this.limit : this.limit - 1;

        }
    }

    private repaginate(newPageinator: number) {
        this.pagination = newPageinator;
        this.GetPageNumbers(this.model);
        this.changePage(this.currentPage);
    }

    private limitBegin(): number {
        let start = this.limit - 5;
        return start;
    }

    public externalReset(): void {
        this.changePage(this.pagenumbers[0]);
    }
}

