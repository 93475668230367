import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AutologinService } from '../../auto-login/autologin.service';

@Component({
  selector: 'migration-banner',
  templateUrl: './migration-banner.component.html',
  styleUrls: ['./migration-banner.component.scss']
})
export class MigrationBannerComponent {

    @Output() bannerDismissed = new EventEmitter<void>();

    constructor(private autologinService: AutologinService) { }

    closeBanner(): void {
        this.bannerDismissed.emit();
    }

    clickToNationalCollege(): void {
        this.autologinService.getAutoLoginString('/')
            .subscribe(autologinTarget => window.location.assign(autologinTarget));
    }

}
