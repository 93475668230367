import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'quotepipe'
})

export class RemoveQuotesPipe implements PipeTransform {
    transform(value: string, format: string = ""): string {

        if (value == '') return '';
        var result = String(value).replace(/"/gm, '');

        return result;
        /*
        //convert special characters and return text
        var div = document.createElement("div");
        div.innerHTML = result;
        return div.textContent || div.innerText; */
    }
}

//Example of use | quotepipe
