
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoginService } from './login.service';

@Component({
    templateUrl: './login-inactive.component.html',
    styleUrls: ['./login-inactive.component.css'],
    providers: [LoginService]
})
export class LoginInactiveComponent implements OnInit {

    public email: string = "";
    public emailSent: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService
    ) { }

    ngOnInit() {

        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.email = params['email'];
        });
    }

    sendEmail(): void {


        let details: any = {
            email: this.email
        }

        this.emailSent = true;

        this.loginService
            .sendActivationEmail(details)
            .subscribe(result => { });
        
    }

}
