
import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { ActivatedRoute, Router, Params, Data } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AuthService } from '../guards/auth.service';

@Component({
    templateUrl: './login-activation-or-reset-failed.component.html',
    styleUrls: ['./login-activation-or-reset-failed.component.css']
})
export class LoginActivationOrResetFailedComponent {

    mode: string = '';
    title: string = '';

    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe(
            (data: Data) => {
                this.mode = data['mode'];
                if (this.mode === 'pinexpired') {
                    this.title = 'Temporary Password Expired';
                }
                else if (this.mode === 'activationexpired') {
                    this.title = 'Activation Link Expired';
                }
                else if (this.mode === 'activationerror') {
                    this.title = 'Activation Error';
                }
                else if (this.mode === 'userlocked') {
                    this.title = 'Locked';
                }
            }
        );
    }
}
