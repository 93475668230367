import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DashboardService } from '../dashboard/dashboard.service';
import { AuthService } from './auth.service';

@Injectable()
export class DashboardPreferenceGuard implements CanLoad, CanActivate {
    constructor(private router: Router, private dashboardService: DashboardService, private authService: AuthService) { }

    canLoad(route: Route): boolean {
        let sendToHome: boolean = true;

        if (!this.authService.loggedIn || !this.authService.showDashboard) {
            this.router.navigate(['/home']);
        }

        return this.authService.loggedIn && this.authService.showDashboard;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let result = true;

        if (!this.authService.loggedIn && !this.authService.showDashboard) {
            result = false;
            this.router.navigate(['/home']);
        }

        return result;
    }
}
