
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CategoryService } from "../../../category/category.service";

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'


@Component({
    selector: 'watchlist-category-sidebar',
    //template:'<div></div>',
    templateUrl: './watchlist.component.html',
    styleUrls: ['./watchlist.component.scss'],
    providers: [CategoryService],

})
export class WatchListCategorySideComponent implements OnInit {

    onWatchList: any;
    constructor(private categoryService: CategoryService, private route: ActivatedRoute)
    { }

    ngOnInit(): void {
        this.route.params.pipe(
            switchMap((params: Params) => this.categoryService.IsOnUserReadingList(+params['id'])))            
            .subscribe(result => {
                this.onWatchList = result;
            })

    }

    addToWatch(): void {
        this.onWatchList.OnWatchList = true;
        let routeId;
        this.route.params.subscribe(params => {
            routeId = +params['id'];
        })
        this.categoryService.AddToReadingList(routeId).subscribe();
    }
}
