<section class="container">
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <sidepanel [h1title]="'Forgotten your password?'">
                <div class="wrapper">
                    <p>If you have forgotten your password, please enter your email address below and we will send you an email containing a temporary password, which will expire after 24 hours. Log in to TheSchoolBus using this and proceed to set a new unique password.</p>

                    <text-field name="email" [(model)]="email" [label]="'Email Address:'" type="text" [error]="emailError" [disabled]="success || actionFailed"></text-field>

                    <button type="button" class="btn btn-orange pull-right" style="margin-top: 10px" (click)="sendEmail()" *ngIf="!success && !actionFailed">Send temporary password</button>

                    <div class="alert alert-success" role="alert" style="margin-top: 10px;" *ngIf="success">
                        <strong>Email Sent:</strong> An email has been sent, please check your inbox including your junk folder.
                    </div>
                    <div class="alert alert-danger" role="alert" style="margin-top: 10px;" *ngIf="actionFailed">
                        <strong>Error:</strong> {{actionFailedMessage}}
                    </div>
                </div>
            </sidepanel>
        </div>
    </div>
</section>
