import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,  BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../guards/auth.service';
import { BaseService } from '../base.service';

@Injectable()
export class LoginService extends BaseService {
    
    constructor(protected http: HttpClient, private auth: AuthService) {
        super(http);
    }

    redirectUrl: string;

    login(details: any): Observable<any> {
        return this.http.post<any>('/api/login/login', details, this.httpOptions).pipe(
            tap((result: any) => {

                this.auth.login(result.userProfile);
            }),
            catchError(this.handleError('Login'))
        );
    }

    sendActivationEmail(details: any): Observable<any> {
        return this.http.post<any>('/api/login/ReSendActivationEmail2', details, this.httpOptions).pipe(
            catchError(this.handleError('sendActivationEmail'))
        );
    }
}
