import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { HeaderService } from '../header/header.service';

@Injectable()
export class CategoryService extends BaseService {

    constructor(protected http: HttpClient, private headerService: HeaderService) {
        super(http);
        this.headerService.getCachedHeaderData().subscribe(data => this.headerCategoryDataSubject.next(data.HeaderCategories));
        }


    private headerCategoryDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public headerCategoryData$: Observable<any> = this.headerCategoryDataSubject.asObservable();

    getCategoryTopics(id: number): Observable<any> {
        return this.http.get('api/topiccategory/getcategorytopics/' + id).pipe(
            catchError(this.handleError('getcategorytopics'))
        );
    }

    IsOnUserReadingList(id: number): Observable<any> {
        return this.http.get('api/topiccategory/IsOnUserReadingList/' + id).pipe(
            catchError(this.handleError('IsOnUserReadingList'))
        );
    }

    AddToReadingList(id: number): Observable<any> {
        return this.http.post<any>('api/topiccategory/AddToReadingList/' + id, {}, this.httpOptions).pipe(
            catchError(this.handleError('AddToReadingList'))
        );
    }

    getCategoryCourses(topicCategoryId: number): Observable<any> {
        return this.http.get(`api/topiccategory/getcategorycourses?topicCategoryId=${topicCategoryId}`).pipe(
            catchError(this.handleError('getCategoryCourses'))
        );
    }
}

export enum TopicCategoryIds {
    Trusts = 10,
    SchoolImprovementInspection = 2,
    AdminFinance = 3,
    Safeguarding = 4,
    StaffRecruitment = 5,
    TeachingLearning = 6,
    Governance = 7,
    FacilitiesHealthSafety = 8
}
