<div>
    <section class="container login">
        <div class="row">
            <div class="col-md-5" style="padding-top:30px;">

                <sidepanel [h1title]="'Account Disabled'">
                    <div class="wrapper">
                        <p style="color:#686464;">
                            If you believe there has been a mistake, and your account should be active, please call <strong>01625 464 420</strong> to discuss this issue further.
                        </p>
                    </div>
                </sidepanel>
            </div>
            <div class="col-md-7 hidden-xs">
                <div class="right">
                    <img src="/Img/core/login/locked.png" style="margin-top: 70px" />
                </div>
            </div>
        </div>
    </section>

</div>
