import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../base.service';

@Injectable()
export class TrialService extends BaseService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    trialModalOnInit(): Observable<any> {
        return this.http.get('/api/trial/TrialModalOnInit').pipe(
            catchError(this.handleError('trialModalOnInit'))
        );
    }
}
