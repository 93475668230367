import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'success-modal-content',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.css']
})
export class SuccessModal {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any) { }

}
