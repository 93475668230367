import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'sb-card',
    templateUrl: './sb-card.component.html',

    styleUrls: ['./sb-card.component.css'],

})
export class SbCardComponent {
    //Example colours: Success, Warn, Danger, Info
    @Input() color: string = "none";

}
