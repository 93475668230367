<div *ngIf="news" class="nbg visible-lg-block visible-md-block visible-sm-block mat-elevation-z2">
    <a [routerLink]="'/' + news.Url">
        <div class="row homeTable" style="margin: 0;">
            <div class="col-sm-6 homeTableCell margin-top-bottom-10" style="padding-left: 0;">
                <div>
                    <img [src]="news.NewsLogoM" alt="NewsContentImage" class="overLogo">
                </div>
            </div>

            <div class="col-sm-6 homeTableCell margin-top-bottom-10">
                <div>
                    <h1 class="lH1">{{news.NewsTitle}}</h1>
                    <h2 class="lH2">{{news.NewsSubHeading}}</h2>
                </div>
                <div class="date-tag"><span class="glyphicon glyphicon-time" style="margin-right:5px;"></span>{{news.NewsDate | date:'dd/MM/yyyy HH:mm'}}</div>
            </div>
        </div>
    </a>
</div>
