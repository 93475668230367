import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { AuthService } from '../../guards/auth.service';
import { SearchService } from '../../search/search.service';
import { HeaderService } from '../header.service';

@Component({
  selector: 'mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {
    @Input() mobileNavData: any;
    mobileNavVisible: boolean;
    searchVisible: boolean;
    subNavTitle: string = '';
    subNavList: any;

    constructor(private renderer: Renderer2, private elementRef: ElementRef, private headerService: HeaderService, private searchService: SearchService, private authService: AuthService) { }

    ngOnInit(): void {
        
    }

    ngOnChanges() {
        this.searchService.viewSelectedMobileResult.subscribe(searchItemSelected => {
            if (searchItemSelected) {
                this.searchVisible = false;
                this.mobileNavVisible = false;
                document.getElementById("main-mobile-nav").style.width = "0";
                document.getElementById("sub-mobile-nav").style.width = "0";
                document.getElementById("mobile-search").style.width = "0";
            }
        });
    }

    toggleMobileNav(): void {
        if (this.searchVisible) {
            this.searchVisible = !this.searchVisible;
            document.getElementById("mobile-search").style.width = "0";
        }

        if (!this.mobileNavVisible) {
            document.getElementById("main-mobile-nav").style.width = "100%";
            this.mobileNavVisible = !this.mobileNavVisible;
        } else {
            document.getElementById("main-mobile-nav").style.width = "0";
            document.getElementById("sub-mobile-nav").style.width = "0";
            document.getElementById("mobile-search").style.width = "0";
            this.mobileNavVisible = !this.mobileNavVisible;
        }
    }

    mobileNavBack(): void {
        document.getElementById("sub-mobile-nav").style.width = "0";
    }

    toggleSearch(): void {
        if (this.mobileNavVisible) {
            this.mobileNavVisible = !this.mobileNavVisible;
            document.getElementById("main-mobile-nav").style.width = "0";
            document.getElementById("sub-mobile-nav").style.width = "0";
        }

        if (!this.searchVisible) {
            document.getElementById("mobile-search").style.width = "100%";
            this.searchVisible = !this.searchVisible;
        } else {
            document.getElementById("mobile-search").style.width = "0";
            this.searchVisible = !this.searchVisible;
        }
    }

    getLink(rawLink): string {
        return rawLink?.split('?')[0];
    }

    getQueryParams(rawLink): any {
        if (rawLink?.split('?').length > 1) {
            let rawParams = rawLink.split('?')[1].split('&');
            let result = {};
            rawParams.forEach(x => {
                let keyValue = x.split('=');
                result[keyValue[0]] = keyValue[1];
            });

            return result;
        } else {
            return;
        }
    }

    materialImage(iconRef) {
        return `url('../../../../Img/icons/material/${iconRef}.png')`;
    }

    openSubMobileNav(parentMenuItem) {
        document.getElementById("sub-mobile-nav").style.width = "100%";
        this.subNavTitle = parentMenuItem.DisplayText;
        this.subNavList = parentMenuItem.HeaderToolbarItems;
    }

    closeSubMobileNav() {
        document.getElementById("main-mobile-nav").style.width = "0";
        document.getElementById("sub-mobile-nav").style.width = "0";
        this.mobileNavVisible = !this.mobileNavVisible;
    }

}
