import { Component, Input } from '@angular/core';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent {
    @Input() noHome: boolean;
    
    @Input() childTitle: string;
    @Input() grandChildTitle: string;
    @Input() greatGrandChildTitle: string;
    @Input() greatGreatGrandChildTitle: string;

    @Input() child: string;
    @Input() grandChild: string;
    @Input() greatGrandChild: string;
    @Input() greatGreatGrandChild: string;

    @Input() childQuery: any;
    @Input() grandChildQuery: any;
    @Input() greatGrandChildQuery: string;
    @Input() greatGreatGrandChildQuery: string;
}
