<div class="myModal nps-modal" id="nps">
    <div class="dismiss-button" (click)="dismiss()">
        <div><span class="fa fa-close"></span></div>
        <div>CLOSE</div>
    </div>
    <div class="survey" *ngIf="!submitted">
         <h3>How likely are you to recommend TheSchoolBus to a colleague?</h3>
        <div class="score">
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 0}" #tooltip="matTooltip" matTooltip="Very unlikely" (click)="addScore(0)">0</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 1}" #tooltip="matTooltip" matTooltip="Very unlikely" (click)="addScore(1)">1</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 2}" #tooltip="matTooltip" matTooltip="Very unlikely" (click)="addScore(2)">2</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 3}" #tooltip="matTooltip" matTooltip="Very unlikely" (click)="addScore(3)">3</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 4}" #tooltip="matTooltip" matTooltip="Very unlikely" (click)="addScore(4)">4</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 5}" #tooltip="matTooltip" matTooltip="Unlikely" (click)="addScore(5)">5</button>
            <button class="circle neg" [ngClass]="{'active-neg': response.score === 6}" #tooltip="matTooltip" matTooltip="Unlikely" (click)="addScore(6)">6</button>
            <button class="circle pas" [ngClass]="{'active-pas': response.score === 7}" #tooltip="matTooltip" matTooltip="Fairly likely" (click)="addScore(7)">7</button>
            <button class="circle pas" [ngClass]="{'active-pas': response.score === 8}" #tooltip="matTooltip" matTooltip="Fairly likely" (click)="addScore(8)">8</button>
            <button class="circle pos" [ngClass]="{'active-pos': response.score === 9}" #tooltip="matTooltip" matTooltip="Very likely" (click)="addScore(9)">9</button>
            <button class="circle pos" [ngClass]="{'active-pos': response.score === 10}" #tooltip="matTooltip" matTooltip="Very likely" (click)="addScore(10)">10</button>
        </div>
        <div class="comment-section" *ngIf="response.score != null">
            <textarea id="w3review" name="w3review" rows="3" cols="50" [(ngModel)]="response.comment" placeholder="{{followOnMessage}}"></textarea>
            <button class="btn btn-submit" (click)="submit()">submit</button>
        </div>
        <div *ngIf="response.comment" class="share-feedback">
            <mat-checkbox [(ngModel)]="response.allowFeedbackPublicSharing">I'm happy for my feedback to be shared publicly</mat-checkbox>
        </div>
    </div>
    <div class="submitted-message" *ngIf="submitted">
        <p>Thank you for your feedback, {{name}}<p>
       <p> Your score and feedback will go towards making TheSchoolBus even better </p>
    </div>
</div>
