import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'header-category-links',
  templateUrl: './category-links.component.html',
  styleUrls: ['./category-links.component.scss']
})
export class CategoryLinksComponent implements OnInit {

  @Input() categories: Array<HeaderCategory>;

  constructor() { }

  ngOnInit(): void {

  }

}

export interface HeaderCategory {
    HeaderCategoryId: number;
    Name: string;
    DisplayOrder: number;
    Url: string;
    ColourHex: string;
}
