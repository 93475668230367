import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Routes, RouterModule, UrlHandlingStrategy, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginModule } from './login/login.module';

import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { FooterComponent } from './footer/footer.component';
import { RedirectToHomeComponent } from './redirect/redirect-to-home.component';
import { TermsAndConditionsModule } from './legal/termsandconditions/termsandconditions.module';
import { TandCModalComponent } from './modal/tandc/tandc-modal.component';

import { TrialModal } from './modal/trial/trial-modal.component';
import { DeleteModal } from "./modal/delete/delete-modal.component";
import { SuccessModal } from './modal/success/success-modal.component';
import { ErrorModal } from './modal/error/error-modal.component';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdminGuard } from './guards/admin-guard.service';
import { LoginGuard } from './guards/login-guard.service';
import { LogoutGuard } from './guards/logout-guard.service';
import { StopTrialGuard } from './guards/stop-trial-guard.service';
import { LogoutAndTrialGuard } from './guards/logout-and-trial-guard.service';
import { StopOnlyFullLicenceGuard } from './guards/stop-only-full-licence-guard-service';
import { DashboardPreferenceGuard } from './guards/dashboard-preference-guard';
import { AuthService } from './guards/auth.service';
import { LoginService } from './login/login.service';
import { DashboardService } from './dashboard/dashboard.service';
import { RequestInterceptorService } from './guards/httpinterceptor/request-interceptor.service';
import { SuccessMessage } from './account/components/success.message/success.message.component';
import { ErrorMessage } from './account/components/error.message/error.message.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CookieAcceptancePopupComponent } from './legal/cookies/cookie-acceptance-popup/cookie-acceptance-popup.component';
import { ManageCookiesComponent } from './legal/cookies/manage-cookies/manage-cookies.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NpsCollectionModule } from './nps-collection/nps-collection.module';
import { HeaderService } from './header/header.service';

const defaultRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'nationalcollege',
        loadChildren: () => import('./national-college-upgrade/national-college-upgrade.module').then(m => m.NationalCollegeUpgradeModule),
        canLoad: [LoginGuard]
    },
    {
        path: 'v2admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [AdminGuard]
    },
    {
        path: 'compliancemanager',
        loadChildren: () => import('./policy-manager/policy-manager.module').then(m => m.PolicyManagerModule)
    },
    {
        path: 'schooldocuments',
        loadChildren: () => import('./compliancearea/compliancearea.module').then(m => m.ComplianceManagerAreaModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)

    },
    {
        path: 'helpcentre',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)

    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'mydashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [DashboardPreferenceGuard]
    },
    {
        path: 'watchlist',
        loadChildren: () => import('./watch-list/watch-list.module').then(m => m.WatchListModule)
    },
    {
        path: 'article',
        loadChildren: () => import('./article/article.module').then(m => m.ArticleModule)
    },
    {
        path: 'topic',
        loadChildren: () => import('./topic/topic.module').then(m => m.TopicModule)
    },
    {
        path: 'category',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule)
    },
    {
        path: 'register',
        redirectTo: '/membership',
        pathMatch: 'full'
    },
    {
        path: 'editorialpolicy',
        loadChildren: () => import('./legal/editorialpolicy/editorialpolicy.module').then(m => m.EditorialPolicyModule)
    },
    {
        path: 'privacypolicy',
        loadChildren: () => import('./legal/priv/privacypolicy.module').then(m => m.PrivacyPolicyModule)
    },
    {
        path: 'cookie',
        loadChildren: () => import('./legal/cookies/cookie.module').then(m => m.CookieModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('./legal/termsandconditions/termsandconditions-page.module').then(m => m.TermsAndConditionsPageModule)
    },
    {
        path: 'users',
        loadChildren: () => import('./manageusers/manageusers.module').then(m => m.ManageUsersModule),
        canLoad: [LoginGuard]
    },
    {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canLoad: [LoginGuard]
    },
    {
        path: 'feature',
        loadChildren: () => import('./featured/featured.module').then(m => m.FeaturedModule),
    },
    {
        path: 'ask',
        loadChildren: () => import('./ask/ask.module').then(m => m.AskModule)
    },
    {
        path: 'referral',
        loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule),
        canLoad: [StopTrialGuard]
    },
    {
        path: 'tasks',
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TaskModule)
    },
    {
        path: 'articletype',
        loadChildren: () => import('./articletype/articleType.module').then(m => m.ArticleTypeModule)
    },
    {
        path: 'workweek',
        loadChildren: () => import('./workweek/workweek.module').then(m => m.WorkWeekModule)
    },
    {
        path: 'newandupdated',
        loadChildren: () => import('./newandupdated/newandupdatedpage.module').then(m => m.NewAndUpdatedPageModule)
    },
    {
        path: 'articlelist',
        loadChildren: () => import('./articlelist/article-list.module').then(m => m.ArticleListModule)
    },
    {
        path: 'dailyemailupdates',
        loadChildren: () => import('./dailyemailupdates/daily-email-updates.module').then(m => m.DailyEmailUpdatesModule)
    },
    {
        path: 'ideasandfeedback',
        loadChildren: () => import('./ideasandfeedback/ideas-and-feedback.module').then(m => m.IdeasAndFeedbackModule)
    },
    {
        path: 'tools',
        loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule)
    },
    {
        path: 'services',
        loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'auto-login',
        loadChildren: () => import('./auto-login/auto-login.module').then(m => m.AutoLoginModule)

    },
    {
        path: 'marketing',
        loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule),
        canLoad: [LogoutGuard]
    },
    {
        path: 'membership',
        loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule),
        canLoad: [LogoutGuard]
    },
    {
        path: 'thank-you',
        loadChildren: () => import('./membership/membership-thank-you/membership-thank-you.module').then(m => m.MembershipThankYouModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
    }
];

@NgModule({
    imports: [
        BrowserModule,
        ServiceWorkerModule.register('/dist/ngsw-worker.js', { enabled: false }),
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        MatSidenavModule,
        MatListModule,
        MatExpansionModule,
        MatDialogModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatCheckboxModule,
        FormsModule,
        HeaderModule,
        LoginModule,
        RouterModule.forRoot(defaultRoutes, { useHash: false }),
        TermsAndConditionsModule,
        NpsCollectionModule
    ],
    providers: [
        MatDialog,
        LoginService,
        AdminGuard,
        LoginGuard,
        StopTrialGuard,
        LogoutGuard,
        LogoutAndTrialGuard,
        StopOnlyFullLicenceGuard,
        DashboardPreferenceGuard,
        AuthService,
        HeaderService,
        DashboardService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true }
    ],
    declarations: [
        AppComponent,
        TrialModal,
        FooterComponent,
        DeleteModal,
        SuccessModal,
        ErrorModal,
        RedirectToHomeComponent,
        RedirectToHomeComponent,
        TandCModalComponent,
        CookieAcceptancePopupComponent,
        ManageCookiesComponent
    ],
    entryComponents: [TandCModalComponent, TrialModal, DeleteModal, SuccessModal, ErrorModal, RedirectToHomeComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
