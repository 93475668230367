import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../account/account.service';
import { AuthService } from '../../guards/auth.service';
import { NpsCollectionService } from '../nps-collection.service';

@Component({
      selector: 'nps-collection-modal',
      templateUrl: './nps-collection-modal.component.html',
      styleUrls: ['./nps-collection-modal.component.scss']
})
export class NpsCollectionModalComponent implements OnInit {
    response = {
        score: null,
        comment: "",
        allowFeedbackPublicSharing: true,
        originUrl: ""
    };
    followOnMessage: string;
    submitted: boolean;
    name: string;


    constructor(
        public dialogRef: MatDialogRef<NpsCollectionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthService,
        private npsCollectionService: NpsCollectionService,
        private account: AccountService
    ) { }

    ngOnInit() {
        this.account.GetCurrentUserInfo().subscribe(data => this.name = data.Firstname)
        this.response.originUrl = window.location.href;
    }

    submit(): void {
        this.authService.NPSLastQueried = Date.now();
        this.authService.NPSDialogueShow = false;
        let userResponse = { ...this.response, allowFeedbackPublicSharing: this.response.comment.length > 0 && this.response.allowFeedbackPublicSharing };
        this.npsCollectionService.postNPSSurvey(userResponse)
            .subscribe(() => {
                this.submitted = true;
                setTimeout(() =>  this.dialogRef.close(), 5000)
            });
    }

    addScore(score: number): void {
        this.response.score = score;
        this.setFollowOnMessage(score);
    }

    dismiss(): void {     
        this.authService.NPSLastQueried = Date.now();
        this.authService.NPSDialogueShow = false;
        if (!this.submitted) {
            this.npsCollectionService.postNPSSurvey(this.response)
                .subscribe(result => {
                    this.dialogRef.close()
                });
        }
        else {
            this.dialogRef.close();
        }
    }
    setFollowOnMessage(score: number) {
        if (score < 7) return this.followOnMessage = "Before I could recommend you, I would need to see you improve…";
        if (score < 9) return this.followOnMessage = "I would be more likely to recommend you if…";
        if (score > 8) return this.followOnMessage = "My favourite part is…";
    }
}
