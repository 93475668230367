
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'
import { NewsService } from '../../../news/news.service'


@Component({
    selector: 'news-mostpopular',
    templateUrl: './mostpopular.component.html',
    styleUrls: ['./mostpopular.component.scss'],
    providers: [NewsService]
})
export class NewsMostPopularComponent implements OnInit{
    @Input() noCat: boolean;

    popular: any;

    constructor(
        private newsService: NewsService,
        private route: ActivatedRoute)
    { }

    ngOnInit(): void {
        this.newsService.getNewsMostPopular().subscribe(result => this.popular = result);
    }
}
