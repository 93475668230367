import { Component, OnInit, Input } from '@angular/core';
@Component({
    selector: 'success-message',
    templateUrl: './success.message.component.html',
    styleUrls: ['./success.message.component.css'],

})
export class SuccessMessage implements OnInit{

    @Input() height: string;

    ngOnInit(): void {
    }

}