
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoginService } from '../login.service';
import { PasswordResetService } from './password-reset.service';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    providers: [LoginService, PasswordResetService]
})
export class ForgotPasswordComponent implements OnInit {
    
    email: string = '';
    emailError: string = '';
    success: boolean = false;
    successMessage: string = '';
    actionFailed: boolean = false;
    actionFailedMessage: string = 'Unknown error';
    userVisitId: number;

    constructor(
        private loginService: LoginService,
        private passwordResetService: PasswordResetService,
        private titleService: Title,
        private metaService: Meta) {

        titleService.setTitle('Forgot Password - TheSchoolBus');
        this.metaService.updateTag({ content: '' }, "name='description'");
    }

    ngOnInit() {
        this.userVisitId = Math.floor(Math.random() * 999999);
        this.passwordResetService.logPageVisit(this.userVisitId).subscribe(() => { });
    }

    validateEmail(): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(this.email)) {
            this.emailError = 'Please enter a valid email address';
            return false;
        }
        else {
            this.emailError = '';
            return true;
        }
    }


    sendEmail(): void {

        this.successMessage = '';

        if (this.validateEmail()) {

            this.passwordResetService.sendForgotPasswordEmail(this.email, this.userVisitId)
                .subscribe(result => {
                    this.successMessage = result;
                    this.success = true;
                }, error => {
                    this.actionFailedMessage = error.error;
                    this.actionFailed = true;
                });
        }
    }
}
