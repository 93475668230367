import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';


import { HeaderComponent } from './header.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { AccountComponent } from './account/account.component';
import { SearchComponent } from './search/search.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CategoryLinksComponent } from './category-links/category-links.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { MigrationBannerComponent } from './migration-banner/migration-banner.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        HeaderComponent,
        HeaderLogoComponent,
        AccountComponent,
        SearchComponent,
        ToolbarComponent,
        CategoryLinksComponent,
        MobileNavComponent,
        MigrationBannerComponent
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
