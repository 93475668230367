
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ArticleService } from '../../../article/article.service'

@Component({
    selector: 'watchlist-sidebar',
    templateUrl: './watchlist.component.html',
    styleUrls: ['./watchlist.component.css'],
    providers: [ArticleService]
})
export class WatchListSideComponent implements OnInit {

    @Input() articleId: any;
    onWatchList: boolean;
    constructor(private articleService: ArticleService, private route: ActivatedRoute)
    { }

    ngOnInit(): void {

        this.route.params.pipe(
            switchMap((params: Params) => this.articleService.HasUserSubscription(+params['id'])))            
            .subscribe(result => {
                this.onWatchList = result;
            });
    }

    addToWatch(): void {
        this.onWatchList = true
        this.articleService.AddToReadingList(this.articleId).subscribe();
    }
}
