<h1 mat-dialog-title style="font-weight: 800;">Welcome to your free trial!</h1>
<div mat-dialog-content>

    <div class="myModal test">
        <div class="col-sm-12 margin10" style="padding-bottom: 20px;">
            <div>
                <p>We want you to make the most of your trial, so here are a few hints and tips to help you get started:</p>
                <ol>
                    <li style="margin-bottom:10px;">If you’re looking for the most current articles, the ‘Featured Content’, ‘Most Popular’, and ‘New and Updated’ sections are the place to start – they’re all on the homepage.</li>
                    <li style="margin-bottom:10px;">You can unlock 5 exclusive resources of your choice – click carefully, as each article you open will deduct 1 article view.</li>
                    <li style="margin-bottom:10px;">Keep track of your remaining views with the panel in the top right-hand corner of each article.</li>
                    <li style="margin-bottom:10px;">‘Premium content’ is reserved for subscribers only, but don’t worry – if you open a premium article, it won’t affect your article views. </li>
                    <li style="margin-bottom:10px;">You’ll have access for 72 hours, so you can also browse other exciting TheSchoolBus features on offer and get a feel for how it would benefit your school.</li>
                </ol>

                <p style="margin-bottom: 10px;">If you have any questions during your trial or want to see more, click the 'Book a tutorial' button or get in touch on 01625 467 530 and we’ll be happy to help.</p>
                <p>Enjoy your trial!</p>
               
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="trial-button-area">
    <button mat-raised-button (click)="continue()" class="trial-button" color="primary">Go to homepage</button>
    <a href="https://hub4leaders.co.uk/free-demo/the-school-bus-free-demo/" target="_blank" mat-raised-button class="trial-button" color="accent">Book a tutorial</a>
</div>
