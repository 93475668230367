
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'
import { AuthService } from '../../guards/auth.service';

@Component({
    templateUrl: './termsandconditions.component.html',
    selector: 'terms-and-conditions'
})
export class TermsAndConditionsComponent implements OnInit {
    constructor(private titleService: Title, private authService: AuthService) {
    }

    ngOnInit(): void {
        this.authService.pageTitle = this.titleService.getTitle();
        this.titleService.setTitle("Terms and Conditions")
    }
}
