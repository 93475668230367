import { Component, OnInit, Inject } from '@angular/core';
import { TrialService } from './trial-modal.service';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: './trial-modal.component.html',
    styleUrls: ['./trial-modal.component.scss'],
    providers: [TrialService]
})
export class TrialModal implements OnInit {

    trialUserData: any = {};

    constructor(
        public dialogRef: MatDialogRef<TrialModal>,
        private trialService: TrialService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.trialService.trialModalOnInit()
            .subscribe(result => {
                this.trialUserData = result;
            });
    }

    continue(): void {
        this.dialogRef.close();
    }

    watch(): void {
        this.dialogRef.close();
        this.router.navigateByUrl("/watchlist");
    }

    signUp(): void {
        this.dialogRef.close();
        this.router.navigateByUrl("/register");
    }
}
