
<h1 mat-dialog-title>TheSchoolBus - Terms And conditions</h1>
<div mat-dialog-content class="mat-dialog-content" (scroll)="onScroll($event.target)">
    <div class="myModal test" id="terms">
        <div class="" style="padding-bottom: 20px;">
            <div style="width:100%;">
                <terms-and-conditions></terms-and-conditions>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <a mat-raised-button color="warn"  href="/logout" tabindex="-1">Decline</a>
    <button mat-raised-button color="primary" (click)="accept()" tabindex="-1" [disabled]="disabled">Accept</button>
    <br />
    <p style="margin-left: 20px;">Please take time to read our Privacy Policy - it can be found in the footer of the website</p>

</div>


