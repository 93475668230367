<div *ngIf="feature" class="nbg mat-elevation-z2"> <!--class="nbg hidden-lg hidden-md"-->
    <a [routerLink]="'/' + feature.Url">
        <div class="row" style="margin: 0;" *ngIf="showImg">
            <div class="col-xs-12" style="padding: 0;">
                <img [src]="feature.ImageUrlM" alt="{{feature.Title}}" class="overLogo">
            </div>
        </div>

        <div class="row" style="margin: 0;" *ngIf="showSubHeader">
            <div class="col-xs-12 mBox">
                <h1 class="margin-top-bottom-10 mH1">{{feature.Title}}</h1>
                <h2 class="margin-top-bottom-10 mH2">{{feature.Subheading}}</h2>
            </div>
        </div>
       <div class="row" style="margin: 0;" *ngIf="!showSubHeader">
            <div class="col-xs-12 mBoxLine2">
                <h1 class="margin-top-bottom-10 mH1">{{feature.Title}}</h1>

            </div>
        </div>
    </a>
</div>
