
import { Component } from '@angular/core';

@Component({
    templateUrl: './login-expired.component.html',
    styleUrls: ['./login-expired.component.scss']
})
export class LoginExpiredComponent {



}
