import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
} from '@angular/router';

import { AuthService } from './auth.service';


/**
 * A guard which should prevent loading or displaying a page when (1) the user is not logged in, or (2) when the user is logged as a trial user.
 */
@Injectable()
export class StopTrialGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let url: string = state.url;
        return this.checkCredentials(url);
    }


    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        return this.canActivate(route, state);
    }


    canLoad(route: Route): boolean {

        let url = `/${route.path}`;
        return this.checkCredentials(url);
    }


    /**
     * Checks whether the user is logged in and whether it's a trial user or not. Will return true if the user is logged in (but not as a trial user). If the user is not logged in, or if the user is logged in as a trial user, it should navigate the user back to the home-page and return false.
     * @param url The URL to check.
     */
    checkCredentials(url: string): boolean {

        if (this.authService.authenticated && !this.authService.hasRole('TrialUser')) {

            return true;
        }
        else {

            this.router.navigate(['/home']);
        }

        return false;
    }
}
