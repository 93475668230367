import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'text-field-alt',
    templateUrl: './textField.component.html',
    styleUrls: ['./textField.component.css'],

})
export class TextFieldAlt {

    @Input() model: any;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() error: string;
    test: string;
    //Emit the event change
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    focuslabel: boolean = false;

    onBlur = function () {
        this.focuslabel = false;
    }
    onFocus = function () {
        this.focuslabel = true;
    }
    //Check for change
    changeModel(change: any) {
        this.model = change;
        this.modelChange.emit(change);
    }
    //Error Checker
    checkError = function () {
        return this.error && this.error.length > 0 ? true : false;
    }
    //Focus element
    inputSelect = function (f: any) {
        f.focus();
    }


}