
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
})
export class ShareComponent {
    @Input() url: string;
    @Input() title: string;
    @Input() summary: string;
}
