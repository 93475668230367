import { Component, Input } from '@angular/core';

@Component({
    selector: 'news-lg',
    templateUrl: './news-lg.component.html',
    styleUrls: ['./news-lg.component.scss'],
})
export class NewsLgComponent {

    @Input() news: any;
}
