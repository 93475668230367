<div class="dialog">
    <div class="warning-header">
        <span class="cross glyphicon glyphicon-remove-circle"></span>
    </div>

    <div class="myModal">
        <div class="col-sm-12 margin10" style="margin-top:15px;margin-bottom:10px;">
            <h1 class="modal-title">Are you sure?</h1>
        </div>
        <div class="col-sm-12 margin10" style="padding-bottom:20px;">
            {{data.info}}
        </div>

        <div class="col-sm-12" style="padding-bottom:10px;overflow:auto;">
            <div class="col-sm-6 margin-top-bottom-20">
                <button color="primary" mat-raised-button [mat-dialog-close]="false" tabindex="2">No</button>
            </div>
            <div class="col-sm-6 margin-top-bottom-20">
                <button mat-raised-button color="warn" style="float:right;" [mat-dialog-close]="true">Yes</button>
            </div>
        </div>
    </div>
</div>
