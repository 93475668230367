<sidepanel title="Watch list">
    <div class="select sideBox" (click)="addToWatch()" *ngIf="!onWatchList">
        <div class="fa fa-bell-o send"></div>
        <div class="block">Add to my Alert Manager and notify me when this changes.</div>
    </div>

    <div>
        <a class="select sideBox" [routerLink]="'/watchlist'" *ngIf="onWatchList">
            <div class="fa fa-bell-o list"></div>
            <div class="block block-alt">You’ll be notified when this changes. Click here to view your Alert Manager.</div>
        </a>
    </div>
</sidepanel>

