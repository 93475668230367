import { Component, Inject, HostListener } from '@angular/core';
import { TAndCService } from './tandc-modal.service'
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../guards/auth.service';

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: './tandc-modal.component.html',
    styleUrls: ['./tandc-modal.component.css'],
    providers: [TAndCService]
})
export class TandCModalComponent {
    disabled: boolean = true;
    count: number = 0;

    constructor(
        public dialogRef: MatDialogRef<TandCModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private tAndCService: TAndCService,
        private authService: AuthService,
        public quickFindModal: MatDialog
    ) { }

    accept(): void {

        this.authService.acceptedTsbTandC = true;
        if (this.authService.isFirstLogOn) this.authService.firstTermsAccepted(true);
        this.tAndCService.acceptTAndC()
            .subscribe(result => {
                this.dialogRef.close()
            });       
    }

    @HostListener('scroll', ['$event.target'])
    onScroll(elem) {
        if ((elem.offsetHeight + elem.scrollTop) >= elem.scrollHeight - 10) {
            this.disabled = false;
        }
    }
}
