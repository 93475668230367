<div class="blue-container">
    <h1 style="color: #FFF; font-weight: 800; margin-bottom: 20px;">Log in</h1>
    <div class="wrapper">
        <text-field name="email" [(model)]="details.email" [disabled]="actionInProgress" [label]="'Email address'" type="email" (keyup.enter)="onEnter()"></text-field>
        <text-field name="password" [(model)]="details.password" [disabled]="actionInProgress" [label]="'Password'" type="password" (keyup.enter)="onEnter()"></text-field>
        <div class="btnwrapper">
            <a routerLink="/login/forgotpassword" class="pull-left" tabindex="-1">Forgotten your password?</a>
            <button type="submit" class="btn btn-lg btn-tsb-orange" tabindex="3" [disabled]="actionInProgress" (click)="login()">Log in</button>
        </div>
        <div>
            <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> {{errorMessage}}
            </div>
        </div>
    </div>
    <br />
    <h2 style="color: #FFF; margin-bottom: 20px; font-weight: 800">Not a member yet?</h2>
    <p style="font-size:13px; color: #FFF;"> Sign up today to join 20,000+ school leaders and governors using TheSchoolBus to stay up-to-date and compliant.</p>
    <div class="notmember">
        <a href="https://nationalcollege.com/pricing" class="btn btn-tsb-orange signup">BECOME A MEMBER</a>
        <span style="padding: 1px 26px;">or</span>
        <a target="_blank" href="https://nationalcollege.com/pages/request-a-demo" class="btn btn-tsb-orange findmore">FIND OUT MORE</a>
    </div>
</div>


