import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htmltoplaintext'
})

export class HtmlToPlainTextPipe implements PipeTransform {
    transform(value: string, format: string = ""): string {

        if (value == '') return '';

        //strip html elements
        var result = String(value).replace(/<[^>]+>/gm, '');

        //convert special characters and return text
        var div = document.createElement("div");
        div.innerHTML = result;
        return div.textContent || div.innerText;
    }
}