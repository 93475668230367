import { Component, OnInit, Input } from '@angular/core';
@Component({
    selector: 'account-container',
    templateUrl: './account.container.component.html',
    styleUrls: ['./account.container.component.scss'],

})
export class AccountContainer {
    @Input() name: String;
    @Input() glyph: String;
    @Input() disabled: Boolean;

}
