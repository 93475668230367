import { Component, Input } from '@angular/core';

@Component({
    selector: 'feature-lg',
    templateUrl: './feature-lg.component.html',
    styleUrls: ['./feature-lg.component.scss'],
})
export class FeatureLgComponent {

    @Input() feature: any;
}
