<div>
    <section class="container login">
        <div class="row">
            <div class="col-md-5" style="padding-top:30px;">

                <sidepanel [h1title]="'Account Expired'">
                    <div class="wrapper">
                        <p style="color:#686464; margin-top: 10px;">
                            Your account has expired.<br /><br /> Use the links below to renew your account to regain access or alternatively please call <strong>01625 467 530</strong> to chat to a member of the sales team.
                        </p>
                    </div>
                    <div class="notmember">
                        <div class="bt">
                            <a routerLink="/register" class="btn btn-orange signup" style="margin-right:20px;">sign up now</a>
                        </div>
                        <div class="or">
                            <span>or</span>
                        </div>
                        <div class="bt">
                            <a target="_self" href="http://hub4leaders.co.uk/" class="btn btn-orange findmore">find out more</a>
                        </div>
                    </div>
                </sidepanel>
            </div>
            <div class="col-md-7 hidden-xs">
                <div class="right">
                    <img src="/Img/core/login/locked.png" style="margin-top: 70px" />
                </div>
            </div>
        </div>
    </section>

</div>
