import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class NpsCollectionService extends BaseService {
    constructor(protected http: HttpClient) { super(http); }

    postNPSSurvey(response): Observable<any> {
        return this.http.post('/api/NPS/PostNPSSurvey', response).pipe(
            catchError(this.handleError('npsModal'))
        );
    }
}
