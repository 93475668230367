<div>
    <ol class="breadcrumb">
        <li *ngIf="!noHome"><a routerLink="/home">Home</a></li>

        <li *ngIf="child"><a [routerLink]="child" [queryParams]="childQuery">{{childTitle}}</a></li>
        <li *ngIf="grandChild"><a [routerLink]="grandChild" [queryParams]="grandChildQuery">{{grandChildTitle}}</a></li>
        <li *ngIf="greatGrandChild"><a [routerLink]="greatGrandChild" [queryParams]="greatGrandChildQuery">{{greatGrandChildTitle}}</a> </li>
        <li *ngIf="greatGreatGrandChild"><a [routerLink]="greatGreatGrandChild" [queryParams]="greatGreatGrandChildQuery">{{greatGreatGrandChildTitle}}</a> </li>

        <li class="active"></li>
    </ol>
</div>
