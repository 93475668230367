import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlfriendlypipe'
})

export class UrlFriendlyPipe implements PipeTransform {
    transform(value: string, format: string = ""): string {

        if (value == '') return '';
        var result = String(value).toLowerCase();
        result = result.replace(/ /gm, '-');
        return result;
        /*
        //convert special characters and return text
        var div = document.createElement("div");
        div.innerHTML = result;
        return div.textContent || div.innerText; */
    }
}

//Example of use | urlfriendlypipe
