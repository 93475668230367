
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { LoginService } from '../login.service';
import { PasswordResetService } from './password-reset.service';

@Component({
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss'],
    providers: [LoginService, PasswordResetService]
})
export class SetPasswordComponent implements OnInit {
    
    token: string;
    passwordReset: any = {
        NewPassword: '',
        CheckPassword: '',
        Email: '',
        Token: '',
        UserId: 0
    };
    success: boolean = false;
    newpasswordError: string = '';
    checkpasswordError: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private passwordResetService: PasswordResetService,
        private router: Router,
        private titleService: Title,
        private metaService: Meta) {

        titleService.setTitle('Set Password - TheSchoolBus');
        this.metaService.updateTag({ content: '' }, "name='description'");
    }


    ngOnInit() : void {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.passwordReset.Token = params['TokenID'];
        });

        this.passwordResetService.resetPasswordWithToken(this.passwordReset)
            .subscribe(result => {

                this.passwordReset = result;

            });
    }


    validate(): boolean {

        this.newpasswordError = '';
        this.checkpasswordError = '';
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

        if (this.passwordReset.NewPassword == null || this.passwordReset.NewPassword == "") {

            this.newpasswordError = 'Please enter a New Password';
            return false;
        }
        
        if (this.passwordReset.NewPassword.length < 8 && this.passwordReset.NewPassword.length >= 1) {

            this.newpasswordError = 'Password must be at least 8 characters long';
            return false;
        }

        if (!re.test(this.passwordReset.NewPassword)) {

            this.newpasswordError = 'Password must contain at least one uppercase, one lowercase letter and one number';
            return false;
        }

        if (this.passwordReset.CheckPassword == null || this.passwordReset.CheckPassword == "") {

            this.checkpasswordError = 'Please Confirm New Password';
            return false;
        }

        if (this.passwordReset.NewPassword != this.passwordReset.CheckPassword) {

            this.checkpasswordError = 'Passwords do not match please re-enter';
            return false;
        }

        return true;
    }


    save(): void {

        this.success = false;

        if (this.validate()) {

            this.passwordResetService.changeUserPassword(this.passwordReset)
                .subscribe(result => {
                    this.success = true;
                });
        }
    }
}
