
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ArticleService } from '../../../article/article.service'


@Component({
    selector: 'new-and-updated',
    templateUrl: './newandupdated.component.html',
    styleUrls: ['./newandupdated.component.scss'],
    providers: [ArticleService]
})
export class NewAndUpdatedComponent implements OnInit{

    popular: any;
    @Input() articleTypeId: number;
    @Input() categoryId: number;

    constructor(
        private articleService: ArticleService,
        private route: ActivatedRoute)
    { }

    ngOnInit(): void {

        if (this.articleTypeId) {

            this.articleService.getNewAndUpdatedArticlesByType(this.articleTypeId)
                .subscribe(popular => this.popular = popular);
        }
        else if (this.categoryId) {

            this.articleService.getNewAndUpdatedArticles(this.categoryId)
                .subscribe(popular => this.popular = popular);

        } else {

            this.route.params.pipe(
                switchMap((params: Params) => this.articleService.getNewAndUpdatedArticles(params['id'])))                
                .subscribe(popular => this.popular = popular);
        }
       
    }
}
