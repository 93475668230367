<h4 class="headerunderline">Share</h4>
<div class="row">
    <div class="box">
        <a target="_blank" href="http://www.facebook.com/sharer/sharer.php?u={{url}}">
            <span class="fa fa-facebook-square facebook fa_with_bg"></span>
        </a>
    </div>
    <div class="box">
        <a target="_blank" href="http://twitter.com/share?url={{url}}&text={{title}}&via=_hub4leaders">
            <i class="fa fa-twitter-square twitter fa_with_bg"></i>
        </a>
    </div>
    <div class="box">
        <a target="_blank" href="https://linkedin.com/shareArticle?mini=true&url={{url}}&title={{title}}&summary={{summary}}&source=schoolbus.co.uk">
            <i class="fa fa-linkedin-square linkedin fa_with_bg"></i>
        </a>
    </div>
    <div class="box">
        <a href="mailto:?subject={{title}}&body=Hello, I found this article today on TheSchoolBus: {{url}} and I thought it would be of interest to you!">
            <i class="fa fa-envelope-square twitter fa_with_bg"></i>
        </a>
    </div>
</div>
