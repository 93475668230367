import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Cookie } from 'ng2-cookies';
import { AuthService } from '../../../guards/auth.service';

@Component({
    selector: 'app-manage-cookies',
    templateUrl: './manage-cookies.component.html',
    styleUrls: ['./manage-cookies.component.scss']
})
export class ManageCookiesComponent implements OnInit {
    analyticsCookies: boolean;
    marketingCookies: boolean;
    loaded: boolean = false;
    alwaysTrue: boolean = true;

    constructor(public dialogRef: MatDialogRef<ManageCookiesComponent>,
        private authService: AuthService) { }

    ngOnInit(): void {
        this.dialogRef.afterOpened().subscribe(() => {
            this.analyticsCookies = true;
            this.marketingCookies = true;
            this.loaded = true;
        });
    }

    saveChoices() {
        if (this.analyticsCookies && this.marketingCookies) {
            Cookie.set('cookie_policy_acceptance', 'all', 365);
        } else {
            const analytics = this.analyticsCookies ? '|analytics' : '';
            const marketing = this.marketingCookies ? '|marketing' : '';
            let choices = `essential${analytics}${marketing}`;

            Cookie.set('cookie_policy_acceptance', choices, 365);
        }

        this.authService.cookiePreferencesSetSubject.next(true);
        this.dialogRef.close();
    }
}
