import { Component, OnInit } from '@angular/core';
import { LogoutService } from './logout.service';
import { AuthService } from '../guards/auth.service';

@Component({
    template: '<div></div>',
    providers: [LogoutService]
})
export class LogoutComponent implements OnInit {
    

    constructor(private logoutService: LogoutService, private authService: AuthService) { }


    ngOnInit(): void {
        this.logoutService.logout(false);
    }
}
