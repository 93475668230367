<div class="dialog">
    <div class="error-header">
        <span class="cross glyphicon glyphicon-ban-circle"></span>
    </div>

    <div class="myModal">
        <div class="col-sm-12 margin10" style="margin-top: 15px; margin-bottom: 10px;">
            <h1 class="modal-title">Error</h1>
        </div>
        <div class="col-sm-12 margin10" style="padding-bottom: 20px;">
            {{data.info}}
        </div>

        <div class="col-sm-4 col-sm-offset-4" style="padding-bottom: 10px; overflow: auto;">
            <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
        </div>
    </div>
</div>
