import { Component, Input } from '@angular/core';

@Component({
    selector: 'news-sm',
    templateUrl: './news-sm.component.html',
    styleUrls: ['./news-sm.component.scss'],
})
export class NewsSmComponent {

    @Input() news: any;
    @Input() showSubHeader: boolean;
    @Input() showImg: boolean = true;
}
