import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class DashboardService extends BaseService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    getUserPreferences(): Observable<any> {
        return this.http.get('/api/UserPreferences/GetUserPreferences').pipe(
            catchError(this.handleError('getUserPreferences'))
        );
    }

    updatePreferences(userPreferences): Observable<any> {
        return this.http.post('/api/UserPreferences/UpdateUserPreferences', userPreferences, this.httpOptions).pipe(
            catchError(this.handleError('updatePreferences'))
        );
    }

    updateShowDashboard(showDashboard: boolean): Observable<any> {
        return this.http.post('/api/UserPreferences/UpdateShowDashboard', { showDashboard: showDashboard }, this.httpOptions).pipe(
            catchError(this.handleError('updateShowDashboard'))
        );
    }

    getUserTasksDue(): Observable<any> {
        return this.http.get('/api/dashboard/GetUserTaskSummary', this.httpOptions).pipe(
            catchError(this.handleError('getUserTasksDue'))
        );
    }

    getNFHRequeats(): Observable<any> {
        return this.http.get('/api/NfhClient/GetClientNfhRequestCurrentList/', this.httpOptions).pipe(
            catchError(this.handleError('getNFHRequests'))
        );
    }

    getUserWatchlist(): Observable<any> {
        return this.http.get('/api/dashboard/GetUserWatchlistSummary', this.httpOptions).pipe(
            catchError(this.handleError('getUserWatchlist'))
        );
    }

    getUserRoles(): Observable<any> {
        return this.http.get('/api/dashboard/GetUserRoles', this.httpOptions).pipe(
            catchError(this.handleError('getUserRoles'))
        );
    }

    getUser(): Observable<any> {
        return this.http.get('/api/dashboard/GetUser', this.httpOptions).pipe(
            catchError(this.handleError('getUser'))
        );
    }

    getFundingLink(): Observable<any> {

        return this.http.get('/api/dashboard/getFundingLink/').pipe(
            catchError(this.handleError('get'))
        );
    }

    getComingSoon(): Observable<any> {

        return this.http.get('/api/dashboard/getComingSoon/').pipe(
            catchError(this.handleError('getComingSoon'))
        );
    }

    getUpdatesInProgress(): Observable<any> {

        return this.http.get('/api/dashboard/getUpdatesInProgress/').pipe(
            catchError(this.handleError('getUpdatesInProgress'))
        );
    }
}
