import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'header-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent  {
    @Input() loggedIn: boolean;
    @Input() myAccount: any;
    @Input() myDocumentsToAction: any;
    hideMenu: boolean = true;

    constructor(public location: Location, private router: Router) { }

    showAccountMenu(): void {
        this.hideMenu = false;
    }

    hideAccountMenu(): void {
        this.hideMenu = true;
    }

    isDocumentsToAction(itemUrl: string): boolean {
        const hasDocsToAction: boolean = itemUrl.indexOf('schooldocuments') > -1;
        return hasDocsToAction;
    }

    documentsToAction() {
        return this.myDocumentsToAction;
    }

    clickedLink(url: string, useRouterLink: boolean): void {
        if (useRouterLink) {
            this.router.navigate([url]);
        }
        else {
            window.open(url, '_blank');
        } 
    }
}
