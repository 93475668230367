import { Component, Input } from '@angular/core';

@Component({
    selector: 'article-container',
    templateUrl: './articlecontainer.component.html',
    styleUrls: ['./articlecontainer.component.css'],
})
export class ArticleContainerComponent {

    @Input() border: string;
    @Input() background: string;

}