
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ArticleService } from '../../../../article/article.service'
import { WatchListService } from '../../../../watch-list/watch-list.service';

@Component({
    selector: 'watchlist-button',
    templateUrl: './watchlist-button.component.html',
    styleUrls: ['./watchlist-button.component.scss'],
    providers: [ArticleService, WatchListService]
})
export class WatchListButtonComponent implements OnInit {
    @Input() type: any;
    @Input() articleId: any;
    onWatchList: boolean;
    constructor(private articleService: ArticleService, private watchListService: WatchListService, private route: ActivatedRoute)
    { }

    ngOnInit(): void {
        if (this.type == "Article") {
            console.log(this.type);
            this.route.params.pipe(
                switchMap((params: Params) => this.articleService.HasUserSubscription(+params['id'])))
                .subscribe(result => {
                    this.onWatchList = result;
                });
        }
        if (this.type == "ResourcePack") {
            this.watchListService.getUserWatchList().subscribe(watchList => {
                if (watchList != undefined) {
                    const watchListCheck = watchList.ResourcePack.filter(entry => entry.ContentId == this.articleId);
                    this.onWatchList = watchListCheck.length > 0;
                }
            });
        }
    }

    addToWatch(): void {
        this.onWatchList = true;
        this.watchListService.addToWatchList(this.articleId, this.type).subscribe();
    }
}
