import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class NewsService extends BaseService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    getLastestNewsv1(): Observable<any> {
        return this.http.get('api/news/GetLastestNews').pipe(
            catchError(this.handleError('getLastestNewsv1'))
        );
    }

    getNewsCategories(): Observable<any> {
        return this.http.get('api/news/NewsCategories').pipe(
            catchError(this.handleError('getNewsCategories'))
        );
    }

    getNewsMostPopular(): Observable<any> {
        return this.http.get('api/news/MostPopular').pipe(
            catchError(this.handleError('getNewsMostPopular'))
        );
    }

    getNewsCategory(id: Number): Observable<any> {
        return this.http.get('api/news/GetNewsCategory/' + id).pipe(
            catchError(this.handleError('getNewsCategory'))
        );
    }

    getNews(id: number): Observable<any> {
        return this.http.get('api/news/GetNews/' + id).pipe(
            catchError(this.handleError('getNews'))
        );
    }

    addTaskToUserList(id: number): Observable<any> {
        return this.http.post('/api/TaskUserTask/Add/' + id, this.httpOptions).pipe(
            catchError(this.handleError('addTaskToUserList'))
        );
    }
}
