import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: '<div></div>'
})

export class RedirectToHomeComponent implements OnInit {
    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
        if (window.location.hostname.indexOf('york') > -1) {
            window.location.replace('/plusv2/');
        } else {
            window.location.replace('/home');
        }
    }
}
