
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'
import { NewsService } from '../../../news/news.service'


@Component({
    selector: 'newscategories',
    templateUrl: './newscategories.component.html',
    styleUrls: ['./newscategories.component.css'],
    providers: [NewsService]
})
export class NewsCategoriesComponent implements OnInit {

    categories: any;

    constructor(
        private newsService: NewsService,
        private route: ActivatedRoute)
    { }

    ngOnInit(): void {
        this.newsService.getNewsCategories().subscribe(result => this.categories = result);
    }
}
