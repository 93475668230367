<div *ngIf="feature" class="nbg visible-lg-block visible-md-block visible-sm-block mat-elevation-z2">
    <a [routerLink]="'/' + feature.Url">
        <div class="row homeTable" style="margin: 0;">
            <div class="col-sm-6 homeTableCell margin-top-bottom-10" style="padding-left: 0;">
                <div>
                    <img [src]="feature.ImageUrlM" alt="{{feature.Title}}" class="overLogo">
                </div>
            </div>

            <div class="col-sm-6 homeTableCell margin-top-bottom-10">
                <div>
                    <h1 class="lH1">{{feature.Title}}</h1>
                    <h2 class="lH2">{{feature.Subheading}}</h2>
                </div>
            </div>
        </div>
    </a>
</div>
