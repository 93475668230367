import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'text-field',
    templateUrl: './textField.component.html',
    styleUrls: ['./textField.component.scss'],

})
export class TextField {

    @Input() model: any;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() error: string;
    @Input() type: string;
    @Input() disabled: boolean;
    @Input() label: string;
    @Input() altclass: string;
    //Emit the event change
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    focuslabel: boolean = false;

    //Check for change
    changeModel(change: any)
    {
        this.model = change;
        this.modelChange.emit(change);
    }
    //Error Checker
    checkError = function () {
       return this.error && this.error.length > 0 ? true : false;
    }

    modelCheck = function () {
       return this.model == undefined || this.model == "" ? false : true;
    }
    
    
}
