import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../components/shared.module';

import { LoginComponent } from './login.component';
import { LoginDisabledComponent } from './login-disabled.component';
import { LoginExpiredComponent } from './login-expired.component';
import { LoginInactiveComponent } from './login-inactive.component';
import { LoginActivationOrResetFailedComponent } from './login-activation-or-reset-failed.component';
import { LogoutComponent } from './logout.component';
import { ForgotPasswordComponent } from './password reset/forgot-password.component';
import { SetPasswordComponent } from './password reset/set-password.component';


const loginRoutes: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'login/disabled', component: LoginDisabledComponent },
    { path: 'login/inactive', component: LoginInactiveComponent },
    { path: 'login/expired', component: LoginExpiredComponent },
    { path: 'login/pinexpired', component: LoginActivationOrResetFailedComponent, data: { mode: 'pinexpired'} },
    { path: 'login/activationexpired', component: LoginActivationOrResetFailedComponent, data: { mode: 'activationexpired'} },
    { path: 'login/activationerror', component: LoginActivationOrResetFailedComponent, data: { mode: 'activationerror' } },
    { path: 'login/userlocked', component: LoginActivationOrResetFailedComponent, data: { mode: 'userlocked' } },
    { path: 'logout', component: LogoutComponent },
    { path: 'login/forgotpassword', component: ForgotPasswordComponent },
    { path: 'login/setpassword', component: LoginComponent }
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(loginRoutes)
    ],
    declarations: [
        LoginComponent,
        LoginDisabledComponent,
        LoginInactiveComponent,
        LoginExpiredComponent,
        LoginActivationOrResetFailedComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        SetPasswordComponent
    ]
})
export class LoginModule { }
