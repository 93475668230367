<div class="banner-container" role="alert">
    <p class="banner-text">
        <span aria-hidden="true">👋</span>
        <strong>NEW:</strong> You can now access all your guidance articles and policy templates on The National College platform.
        <button class="click-here link-flash"
                (click)="clickToNationalCollege()"
                aria-label="Click to visit The National College platform">
            Click here
        </button>
        to check it out!
    </p>
    <button class="close-banner"
            (click)="closeBanner()"
            aria-label="Close banner">
        <img src="../../../../Img/icons/material/dismiss_icon.svg"
             alt="Dismiss icon" />
    </button>
</div>
