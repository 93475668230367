import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { AuthService } from '../guards/auth.service';

@Injectable()
export class LogoutService extends BaseService {
    
    constructor(protected http: HttpClient, private auth: AuthService) {
        super(http);
    }
   
    logout(fromLogin?: boolean): void {

        this.http.get('/api/login/logout')
            .pipe(catchError(this.handleError('logout')))
            .subscribe(() => {
                if (!fromLogin) {
                    this.auth.logout();
                    window.location.replace('/home?loggedOut=true');
                }
            });                    
    }
}
