import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../base.service';

@Injectable()
export class PasswordResetService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }
  
    sendForgotPasswordEmail(email: string, userVisitId: number): Observable<any> {
        return this.http.get('/api/passwordreset/GenerateAndSendTemporaryPassword?email=' + email + '&userVisitId=' + userVisitId).pipe(
            catchError(this.handleError('sendForgotPasswordEmail'))
        );
    }

    resetPasswordWithToken(tokenObj: any): Observable<any> {
        return this.http.post<any>('/api/passwordreset/ResetPasswordWithToken', tokenObj, this.httpOptions).pipe(
            catchError(this.handleError('ResetPasswordWithToken'))
        );
    }

    changeUserPassword(passwordObject: any): Observable<any> {
        return this.http.post<any>('/api/passwordreset/ChangeUserPassword', passwordObject, this.httpOptions).pipe(
            catchError(this.handleError('changeUserPassword'))
        );
    }

    logPageVisit(userVisitId: number): Observable<any> {
        return this.http.get('/api/passwordreset/LogUserPageVisit?userVisitId=' + userVisitId).pipe(
            catchError(this.handleError('logPageVisit'))
        );
    }
}
