<div *ngIf="feature" class="nbg mat-elevation-z2">
    <a [routerLink]="'/' + feature.Url">
        <div class="row" style="margin: 0;">
            <div class="col-xs-12" style="padding: 0;">
                <img [src]="feature.ImageUrlM" alt="FeaturedContentImage" class="overLogo">
            </div>
        </div>

        <div class="row" style="margin: 0;" *ngIf="!showSubHeader">
            <div class="col-xs-12 mBoxLine2">
                <h1 class="margin-top-bottom-10 mH1">{{feature.Title}}</h1>

            </div>
        </div>
    </a>
</div>
