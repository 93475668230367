import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidepanelContainer } from './sidepanel/sidepanel/sidepanel.container.component';
import { MostPopularComponent } from './sidepanel/most popular/mostpopular.component';
import { NewAndUpdatedComponent } from './sidepanel/new and updated/newandupdated.component';

import { FeatureLgComponent } from './featured content/feature-lg.component';
import { FeatureMdComponent } from './featured content/feature-md.component';
import { FeatureSmComponent } from './featured content/feature-sm.component';
import { ParentChildListComponent } from './sublist/parent-child-list.component';
import { ParentChildListLgComponent } from './sublist/components/parent-child-list-lg.component';
import { ParentChildListMdComponent } from './sublist/components/parent-child-list-md.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ArticleContainerComponent } from './article/articlecontainer.component';
import { ShareComponent } from './sidepanel/share/share.component';
import { AttachmentsComponent } from './sidepanel/attachments/attachments.component';
import { TrialboxComponent } from './sidepanel/trialbox/trialbox.component';
import { RelatedContentComponent } from './sidepanel/relatedcontent/relatedcontent.component';
import { WatchListSideComponent } from "./sidepanel/watchlist/watchlist.component";
import { HtmlToPlainTextPipe } from '../pipes/html-to-plain-text.pipe';
import { CategoryFilterPipe } from '../pipes/categoryfilter.pipe';
import { MyFilterPipe } from '../pipes/filter'
import { TextField } from './form.components/text-field/textField.component';
import { TextFieldAlt } from './form.components/text-field-alt/textField.component';
import { RadioButton } from './form.components/radio-button/radioButton.component';
import { SelectComp } from './form.components/select-comp/select.component';
import { NewsMdComponent } from "./news content/news-md.component";
import { NewsSmComponent } from "./news content/news-sm.component";
import { NewsLgComponent } from "./news content/news-lg.component";
import { NewsCategoriesComponent } from "./sidepanel/news categories/newscategories.component";
import { NewsMostPopularComponent } from "./sidepanel/news most popular/mostpopular.component";
import { NgTableComponent } from "./ngtable/ngtable.component";
import { AccountContainer } from "../account/components/account.container.component";
import { RemoveQuotesPipe } from "../pipes/removeqoutes.pipe";
import { WatchListCategorySideComponent } from "./sidepanel/watchlist Category/watchlist.component";
import { UrlFriendlyPipe } from "../pipes/urlfriendly.pipe";
import { SanitizeHtmlPipe } from "../pipes/sanitizehtml.pipe";
import { SuccessMessage } from "../account/components/success.message/success.message.component";
import { ErrorMessage } from '../account/components/error.message/error.message.component';
import { SbCardComponent } from "./sb-card/sb-card.component";
import { SbCardContentComponent } from "./sb-card/sb-card-content/sb-card-content.component";
import { SbCardHeaderComponent } from "./sb-card/sb-card-header/sb-card-header.component";
import { UserPreferencesComponent } from './dashboard/user-preferences.component';
import { DashboardContentComponent } from './dashboard/dashboard-content.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { WatchListButtonComponent } from './sidepanel/watchlist/watchlist-button/watchlist-button.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { NvD3Module } from 'ng2-nvd3';
import { ContentBlockerComponent } from '../content-blocker/content-blocker.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatCardModule,
        MatMenuModule,
        NvD3Module
    ],
    declarations: [
        SidepanelContainer,
        MostPopularComponent,
        NewAndUpdatedComponent,
        FeatureLgComponent,
        FeatureMdComponent,
        FeatureSmComponent,
        ParentChildListComponent,
        ParentChildListLgComponent,
        ParentChildListMdComponent,
        BreadcrumbComponent,
        TextField,
        TextFieldAlt,
        RadioButton,
        SelectComp,
        PaginationComponent,
        ArticleContainerComponent,
        ShareComponent,
        AttachmentsComponent,
        TrialboxComponent,
        RelatedContentComponent,
        WatchListSideComponent,
        WatchListButtonComponent,
        HtmlToPlainTextPipe,
        CategoryFilterPipe,
        NewsMdComponent,
        NewsSmComponent,
        NewsLgComponent,
        NewsCategoriesComponent,
        NewsMostPopularComponent,
        NgTableComponent,
        MyFilterPipe,
        AccountContainer,
        RemoveQuotesPipe,
        WatchListCategorySideComponent,
        UrlFriendlyPipe,
        SanitizeHtmlPipe, 
        SuccessMessage,
        ErrorMessage,
        SbCardComponent,
        SbCardContentComponent,
        SbCardHeaderComponent,
        UserPreferencesComponent,
        DashboardContentComponent,
        BarChartComponent,
        ContentBlockerComponent
    ],
    exports: [
        SidepanelContainer,
        MostPopularComponent,
        NewAndUpdatedComponent,
        FeatureLgComponent,
        FeatureMdComponent,
        FeatureSmComponent,
        ParentChildListComponent,
        ParentChildListLgComponent,
        ParentChildListMdComponent,
        BreadcrumbComponent,
        TextField,
        TextFieldAlt,
        RadioButton,
        SelectComp,
        PaginationComponent,
        ArticleContainerComponent,
        ShareComponent,
        AttachmentsComponent,
        TrialboxComponent,
        RelatedContentComponent,
        WatchListSideComponent,
        WatchListButtonComponent,
        HtmlToPlainTextPipe,
        CategoryFilterPipe,
        NewsMdComponent,
        NewsSmComponent,
        NewsLgComponent,
        NewsCategoriesComponent,
        NewsMostPopularComponent,
        NgTableComponent,
        MyFilterPipe,
        AccountContainer,
        RemoveQuotesPipe,
        WatchListCategorySideComponent,
        UrlFriendlyPipe,
        SanitizeHtmlPipe,
        SuccessMessage,
        ErrorMessage,
        SbCardComponent,
        SbCardContentComponent,
        SbCardHeaderComponent,
        UserPreferencesComponent,
        DashboardContentComponent,
        BarChartComponent,
        ContentBlockerComponent
    ],
})
export class SharedModule { }
