<div class="btn watchlist-button" (click)="addToWatch()" *ngIf="!onWatchList">
    <div class="fa fa-bell-o watchlist-icon"></div>
    <div class="fa fa-bell watchlist-icon-hover"></div>
    <div class="hidden-sm hidden-xs watchlist-text">Add to my Alert Manager and notify me when this changes.</div>
    <div class="hidden-md hidden-lg watchlist-text">Add to Alert Manager</div>
</div>

<div>
    <a class="btn watchlist-button watchlist-active" [routerLink]="'/watchlist'" *ngIf="onWatchList">
        <div class="fa fa-bell watchlist-icon-checked"></div>

        <div class="hidden-xs hidden-sm watchlist-text">You’ll be notified when this changes. Click here to view your Alert Manager.</div>
        <div class="hidden-md hidden-lg watchlist-text">View Alert Manager</div>
    </a>
</div>
