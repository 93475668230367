/// <reference types="node" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (document.referrer != null && document.referrer != "" && localStorage.getItem('referrer') == null) {
    localStorage.setItem('referrer', document.referrer);
}

if (environment.production) {

    const script = document.createElement('script');
    script.innerHTML = `
    var appInsights = window.appInsights || function (config) {
        function r(config) { t[config] = function () { var i = arguments; t.queue.push(function () { t[config].apply(t, i) }) } } var t = { config: config }, u = document, e = window, o = "script", s = u.createElement(o), i, f; for (s.src = config.url || "//az416426.vo.msecnd.net/scripts/a/ai.0.js", u.getElementsByTagName(o)[0].parentNode.appendChild(s), t.cookie = u.cookie, t.queue = [], i = ["Event", "Exception", "Metric", "PageView", "Trace"]; i.length;) r("track" + i.pop()); return r("setAuthenticatedUserContext"), r("clearAuthenticatedUserContext"), config.disableExceptionTracking || (i = "onerror", r("_" + i), f = e[i], e[i] = function (config, r, u, e, o) { var s = f && f(config, r, u, e, o); return s !== !0 && t["_" + i](config, r, u, e, o), s }), t
    }({
        instrumentationKey: "82a66531-607e-4074-ab11-851963515dbd"
    });
    window.appInsights = appInsights;
    appInsights.trackPageView();`

    document.head.appendChild(script);

    enableProdMode();
} else {

    const script = document.createElement('script');
    script.innerHTML = `
    var appInsights = window.appInsights || function (config) {
        function r(config) { t[config] = function () { var i = arguments; t.queue.push(function () { t[config].apply(t, i) }) } } var t = { config: config }, u = document, e = window, o = "script", s = u.createElement(o), i, f; for (s.src = config.url || "//az416426.vo.msecnd.net/scripts/a/ai.0.js", u.getElementsByTagName(o)[0].parentNode.appendChild(s), t.cookie = u.cookie, t.queue = [], i = ["Event", "Exception", "Metric", "PageView", "Trace"]; i.length;) r("track" + i.pop()); return r("setAuthenticatedUserContext"), r("clearAuthenticatedUserContext"), config.disableExceptionTracking || (i = "onerror", r("_" + i), f = e[i], e[i] = function (config, r, u, e, o) { var s = f && f(config, r, u, e, o); return s !== !0 && t["_" + i](config, r, u, e, o), s }), t
    }({
        instrumentationKey: "3999aec9-aa76-4d7b-bd07-f7261229d8db"
    });
    window.appInsights = appInsights;
    appInsights.trackPageView();`

    document.head.appendChild(script);

    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';

    document.head.appendChild(meta);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
