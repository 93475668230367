<div *ngIf="news" class="nbg mat-elevation-z2"> <!--class="nbg hidden-lg hidden-md"-->
    <a [routerLink]="'/' + news.Url">
        <div class="row" style="margin: 0;" *ngIf="showImg">
            <div class="col-xs-12" style="padding: 0;">
                <img *ngIf="news.NewsLogoS" [src]="news.NewsLogoS" alt="NewsContentImage" class="overLogo">
                <img *ngIf="news.NewsLogo" [src]="news.NewsLogo" alt="NewsContentImage" class="overLogo">
            </div>
        </div>

        <div class="row" style="margin: 0;" *ngIf="showSubHeader">
            <div class="col-xs-12 mBox">
                <h1 class="margin-top-bottom-10 mH1">{{news.NewsTitle}}</h1>
                <h2 class="margin-top-bottom-10 mH2">{{news.NewsSubHeading}}</h2>
            </div>
        </div>
       <div class="row" style="margin: 0;" *ngIf="!showSubHeader">
            <div class="col-xs-12 mBoxLine2">
                <h1 class="margin-top-bottom-10 mH1">{{news.NewsTitle}}</h1>

            </div>
        </div>

       <div class="time-stamp"><span class="glyphicon glyphicon-time"></span> {{news.NewsDate | date:'dd/MM/yyyy HH:mm'}}</div>
</a>
</div>
