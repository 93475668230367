<div>
    <h4 class="underline">Free trial</h4>
    <div class="bg-midblue" style="padding-top: 10px;">
        <div class="text-center">Article views left:<br><strong style="font-size: 50px;">{{articleViewsLeft}}</strong></div>
    </div>
    <div class="bg-midblue" style="padding: 10px;">
        <div class="row">
            <div class="col-sm-12" style="margin-bottom: 10px;">
                <a class="btn btn-success btn-block" href="/register" target="_self">Sign up</a>
            </div>
            <div class="col-sm-12">
                <a class="btn btn-info btn-block" href="https://hub4leaders.co.uk/contact/contact-us/#callback" target="_self">Call me back</a>
            </div>
        </div>
    </div>
</div>
