<section class="container">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <sidepanel [h1title]="'Set password'">
                <div class="wrapper">
                    <p>
                        This will set the new password for the login <span style="font-weight:bold"> {{passwordReset.Email}}</span>. Once you have signed in you can change your password at any time by selecting the 'My Account' option and then 'Change Password'.
                    </p>
                    <p>
                        Password must contain at least eight characters, at least one uppercase letter, one lowercase letter and one number
                    </p>
                    <text-field name="password" [(model)]="passwordReset.NewPassword" [label]="'Enter Password'" type="password" [error]="newpasswordError" [disabled]="success"></text-field>
                    <text-field name="confirmpassword" [(model)]="passwordReset.CheckPassword" [label]="'Confirm Password'" type="password" [error]="checkpasswordError" [disabled]="success"></text-field>

                    <div style="margin-bottom:60px;">
                        <button type="button" class="btn btn-orange pull-right" style="margin-top:10px" (click)="save()" *ngIf="!success">Save Password</button>
                    </div>     
                    
                    <div class="alert alert-success" style="margin-top:10px;" *ngIf="success" >Your password has been successfully changed. <a [routerLink]="'/login'">Log In</a></div>
                </div>
            </sidepanel>
        </div>
    </div>
</section>
