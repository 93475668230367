
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

//import { SidepanelContainer } from '../sidepanel/sidepanel.container.component'


@Component({
    selector: 'trialbox',
    templateUrl: './trialbox.component.html',
    styleUrls: ['./trialbox.component.css'],


})
export class TrialboxComponent implements OnInit {

    @Input() articleViewsLeft: number;

    ngOnInit(): void {
    }


}