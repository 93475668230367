import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class AccountService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    GetCurrentUserInfo(): Observable<any> {
        return this.http.get('/api/account/GetCurrentUserInfo').pipe(
            catchError(this.handleError('GetCurrentUserInfo'))
        );
    }

    GetCurrentUserSchoolInfo(): Observable<any> {
        return this.http.get('/api/account/GetCurrentUserSchoolInfo').pipe(
            catchError(this.handleError('GetCurrentUserSchoolInfo'))
        );
    }

    GetCurrentUserSchoolContacts(): Observable<any> {
        return this.http.get('/api/account/GetCurrentUserSchoolContacts').pipe(
            catchError(this.handleError('GetCurrentUserSchoolContacts'))
        );
    }

    savePassword(passwordWrapper: any): Observable<any> {
        return this.http.post<any>('/api/account/ChangePassword', passwordWrapper, this.httpOptions).pipe(
            catchError(this.handleError('ChangePassword'))
        );
    }

    createPassword(passwordWrapper: any): Observable<any> {
        return this.http.post<any>('/api/account/CreatePassword', passwordWrapper, this.httpOptions).pipe(
        );
    }

    saveCurrentUserInfo(userWrapper: any): Observable<any> {
        return this.http.post<any>('/api/account/SaveCurrentUserInfo', userWrapper, this.httpOptions).pipe(
            catchError(this.handleError('SaveCurrentUserInfo'))
        );
    }

    getCurrentUserNotificationSettings(): Observable<any> {
        return this.http.get('/api/account/GetCurrentUserNotificationSettings').pipe(
            catchError(this.handleError('GetCurrentUserNotificationSettings'))
        );
    }

    saveCurrentUserNotificationSettings(notifications: any): Observable<any> {
        return this.http.post<any>('/api/account/SaveCurrentUserNotificationSettings', notifications, this.httpOptions).pipe(
            catchError(this.handleError('SaveCurrentUserNotificationSettings'))
        );
    }
}
