<div class="container page-container">
    <div class="row">
        <div class="col-md-8" style="margin-top: 30px;">
            <h1>FEATURED ARTICLES HERE </h1>
        </div>

        <div class="col-md-4" style="margin-top: 30px;" *ngIf="User">
            <div class="user-info-box" style="background: linear-gradient(rgba(5,54,71,1), rgba(52,113,134,1));">
                <div class="container-fluid">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="hello-box">
                                <h2>Welcome back, {{User?.Name}}</h2>
                            </div>
                        </div>

                        <div class="col-md-12" *ngIf="User.Type != 1 && User.Master == false">
                            <div class="info-box-dash" style="text-align: left !important;">
                                <h2 class="info-title">Quick Links</h2>

                                <div class="quick-links">
                                    <a href="{{Link?.link}}">Funding Favourites ></a>
                                    <a href="/article/dates-for-your-diary/5610">Dates for your diary ></a>
                                    <a href="/users/overview">Manage users ></a>
                                </div>
                                <div class="home-toggle-container home-toggle-wide">
                                    <div>
                                        <label>
                                            <span>TSB view</span>&nbsp;
                                        </label>
                                    </div>
                                    <mat-slide-toggle checked="checked" (change)="showHome()">Dashboard view</mat-slide-toggle>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="info-box-dash">
                                <h2 class="info-title">Tasks due today</h2>
                                <p class="info-content-tasks">{{TasksDue?.DueToday}}</p>
                                <a href="/tasks/mytasks" *ngIf="TasksDue?.DueToday > 0">View my tasks ></a>
                                <a href="/tasks/home" *ngIf="TasksDue?.DueToday == 0">Browse tasks ></a>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="User?.Master == true || User?.Type == 1">
                            <div class="info-box-dash">
                                <h2 class="info-title">Quick Links</h2>
                                <div class="quick-links">
                                    <a href="{{Link?.Link}}">Funding favourites ></a>
                                    <a href="/article/dates-for-your-diary/5610">Dates for your diary ></a>
                                    <a href="/users/overview">Manage users ></a>
                                </div>
                                <div class="home-toggle-container">
                                    <div>
                                        <label>
                                            <span>TSB view</span>&nbsp;
                                        </label>
                                    </div>
                                    <mat-slide-toggle checked="checked" (change)="showHome()">Dashboard view</mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="User?.Master == true || User?.Type == 1">
                            <div class="info-box-dash">
                                <h2 class="info-title">Need Further Help status</h2>
                                <span *ngIf="NFHRequests != 0"><p class="info-content" style="font-size: 14px;" *ngFor="let requests of NFHRequests">{{requests.SubstatusName}}</p></span>
                                <p class="info-content" *ngIf="NFHRequests == 0">No pending requests</p>
                                <a *ngIf="NFHRequests != 0" href="/nfh/myrequests">View all requests ></a>
                                <a *ngIf="NFHRequests == 0" href="/nfh/myrequests">Make a request ></a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info-box-dash">
                                <h2 class="info-title">Watch list updates</h2>
                                <p class="info-content-tasks">{{Watchlists?.watchlistupdate}}</p>
                                <a href="/watchlist">Go to watchlist ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <h1 style="margin-bottom: 250px;">Recommended for you</h1> <!--Spacing in for front end visual - remove when this area goes in-->
        </div>
        <div class="row">
            <div class="comingsoon-uip">
                <div class="col-md-6">
                    <div class="coming-soon article-info-box">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>Coming soon</h3>
                            </div>
                            <div class="col-md-3">
                                <h5>Notify me <br /> when ready</h5>
                            </div>
                            <i class="fa fa-calendar icon-awesome"></i>
                            <div class="article-info-box-inner" *ngFor="let Articles of ComingSoon | slice:0:5">
                                <div class="col-md-10">
                                    <div class="col-md-1"><span class="pull-left type-logo" [ngClass]="Articles.Logo"></span></div>
                                    <div class="col-md-11"><p>{{Articles.ArticleTitle}}</p></div>
                                </div>
                                <div class="col-md-2">
                                    <i class="fa fa-bell font-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="coming-soon article-info-box">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>Updates in progress</h3>
                            </div>
                            <div class="col-md-3">
                                <h5>Notify me <br /> when ready</h5>
                            </div>
                            <i class="fa fa-clock-o icon-awesome"></i>
                            <div class="article-info-box-inner" *ngFor="let Updates of UpdatesInProgress | slice:0:5">
                                <div class="col-md-10">
                                    <div class="col-md-1"><span class="pull-left type-logo" [ngClass]="Updates.Logo"></span></div>
                                    <div class="col-md-11"><p>{{Updates.ArticleTitle}}</p></div>
                                </div>
                                <div class="col-md-2">
                                    <i class="fa fa-bell font-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
