import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'select-comp',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],

})
export class SelectComp implements OnInit{

    @Input() model: any;

    @Input() name: string;
    @Input() error: string;
    @Input() disabled: boolean;
    @Input() label: string;
    @Input() altclass: string;

    //Emit the event change
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    focuslabel: boolean = false;


    //Check for change
    changeModel(change: any) {
        this.model = change;
        this.modelChange.emit(change);
    }
    //Error Checker
    checkError = function () {
        return this.error && this.error.length > 0 ? true : false;
    }

    modelCheck = function () {
        return this.model == undefined || this.model == "" ? false : true;
    }


    ngOnInit(): void {

        
    }

}
