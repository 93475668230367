import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SearchService extends BaseService {
    constructor(protected http: HttpClient) { super(http); }

    public viewSelectedMobileResult$ = new BehaviorSubject<boolean>(false);
    public viewSelectedMobileResult = this.viewSelectedMobileResult$.asObservable();

    getSearchResults(query: string, filter: string): Observable<any> {
        return this.http.get('/api/search/SBSearch?query=' + query + '&filter=' + filter).pipe(
            catchError(this.handleError('getSearchResults'))
        );
    }

    getPopularSearchItemList(): Observable<any> {
        return this.http.get('/api/search/GetPopularSearchItemList').pipe(
            catchError(this.handleError('getPopularSearchItemList'))
        );
    }

    getSearchSuggestions(query: string, filter: string, apiKey: string, isLiveEnvironment: boolean): Observable<any> {
        let functionAddress = 'https://tsbfunctions.azurewebsites.net/api/OnTypeQuery';

        let headers = this.httpOptions.headers.append('Access-Control-Allow-Origin', 'https://tsbfunctions.azurewebsites.net');
        headers = headers.append('Access-Control-Allow-Methods', 'POST, PUT, DELETE, GET, OPTIONS');
        headers = headers.append('Access-Control-Allow-Methods', 'Content-Type, Content-Length, Accept-Encoding, Authorization, X-Requested-With');
        headers = headers.append('x-functions-key', apiKey);

        var payload = {
            Query: query,
            Filter: filter,
            IndexName: isLiveEnvironment ? "SearchIndexNameLive" : "TestSearchIndexName"
        };

        return this.http.post<any>(functionAddress, payload, { headers: headers }).pipe(
            catchError(this.handleError('getSearchSuggestions'))
        );
    }

    getSearchConfig(): Observable<any> {
        return this.http.get('/api/search/GetSearchConfig').pipe(
            catchError(this.handleError('GetSearchConfig'))
        );
    }
}
